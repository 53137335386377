import React, { useState } from 'react';
import { Link } from "react-router-dom"

import { signIn } from '../../../api/auth';
import { errorClass } from '../../../helper';
import { registerUser } from '../../../api/users';
import CircularProgress from "@material-ui/core/CircularProgress";
import { setToken } from "../../../api/token";

export const Registration = ({history}) => {
  const [formErrors, setFormErrors] = useState({email: '', password: ''});
  const [client, setClient] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChanges = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    validateField(key, value);
    setClient({
      ...client,
      [key]: value,
    });
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let emailValid = isEmailValid;
    let passwordValid = isPasswordValid;
    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
        break;
      case 'password':
        passwordValid = value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/);
        fieldValidationErrors.password = passwordValid ? '': 'Password is too short or does not contain special characters and numbers';
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setIsEmailValid(emailValid);
    setIsPasswordValid(passwordValid);
    if (passwordValid && emailValid) {
      setIsFormValid(true);
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await(registerUser(client));
      const token = await(signIn(client.email, client.password));
      setToken(token);
      history.push('/dashboard');
    } catch (error) {
      console.log(error);
    };

    setLoading(false);
  };

  return (
      <section className="auth__popup">
        {loading ? (
          <CircularProgress />
        ) : (
          <form
            className="auth__form"
            onSubmit={handleSubmit}
          >
            <h1 className="auth__title">Registration</h1>
            <div className="auth__field">
              <label
                htmlFor="firstName"
                className="auth__label"
              >
                Firstname
              </label>
              <input
                type="text"
                id="firstName"
                onChange={handleChanges}
                value={client.firstName}
                name="firstName"
                placeholder="John"
                className="auth__input"
              />
            </div>
            <div className="auth__field">
              <label
                htmlFor="lastName"
                className="auth__label"
              >
                Lastname
              </label>
              <input
                type="text"
                id="lastName"
                onChange={handleChanges}
                value={client.lastName}
                name="lastName"
                placeholder="Doe"
                className="auth__input"
              />
            </div>
            <div className="auth__field">
              <label
                htmlFor="email"
                className="auth__label"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                onChange={handleChanges}
                value={client.email}
                name="email"
                placeholder="Email@example.com"
                className={`auth__input ${errorClass(formErrors.email)}`}
                title="Must be valid and contain '@' symbol"
              />
              <p class="error-message">
                {formErrors.email}
              </p>
            </div>
            <div className="auth__field">
              <label
                htmlFor="password"
                className="auth__label"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                onChange={handleChanges}
                value={client.password}
                name="password"
                placeholder="Password"
                className={`auth__input ${errorClass(formErrors.password)}`}
                title="Must contain at least one number and one special symbol, and at least 8 or more characters"
              />
              <p class="error-message">
                {formErrors.password}
              </p>
            </div>
            <div className="auth__links">
              <Link to="/signin" className="auth__link">
                I have account
              </Link>
              <button
                type="submit"
                className="auth__button registration__button"
                disabled={!isFormValid}
              >
                Create account
              </button>
            </div>
          </form>
        )}
      </section>
  )
}

