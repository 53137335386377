import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Page } from '../Page';
import { Modal } from '../../../Modal';
import { images } from '../../../../helper';
import { selectors } from '../../../../redux/store';
import { getUser } from '../../../../api/users';
const moment = require('moment');

export const Insurance = React.forwardRef(({ handleChanges, formData, clientId, signatureUrl, setSignatureUrl }, ref) => {
  const [objectFormSignature, setObjectFormSignature] = useState(null);
  const [client, setClient] = useState(null);
  const currentUser = useSelector(selectors.getCurrentUser);

  const loadClient = async (id) => {
    try {
      const client = await(getUser(id));
      setClient(client);
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    loadClient(clientId)
  }, [clientId]);

  const handleSignature = (signature) => {
    if (typeof signature !== 'boolean') {
      setSignatureUrl(signature)
    };
    setObjectFormSignature(null);
  };

  return (
    <Page ref={ref}>
      <div className="template__page">
        <h1 className="template__primary-title">
          Insurance information:
        </h1>
        <div className="template__field">
          <label className="template__label">
            Name of Subscriber:
            <input
              type="text"
              className="template__input"
              name="subscriber_name"
              value={formData && formData.subscriber_name}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Date of Birth:
            <input
              type="text"
              className="template__input"
              name="subscriber_bod"
              value={formData && formData.subscriber_bod}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Patient Relationship to Subscriber:
            <input
              type="text"
              className="template__input"
              name="subscriber_relation"
              value={formData && formData.subscriber_relation}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Subscriber Phone Number:
            <input
              type="text"
              className="template__input"
              name="subscriber_phone"
              value={formData && formData.subscriber_phone}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Insurance Name:
            <input
              type="text"
              className="template__input"
              name="insurance_name"
              value={formData && formData.insurance_name}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Insurance Address:
            <input
              type="text"
              className="template__input"
              name="insurance_address"
              value={formData && formData.insurance_address}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Insurance Member ID:
            <input
              type="text"
              className="template__input"
              name="insurance_member_id"
              value={formData && formData.insurance_member_id}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Effective Date of Coverage:
            <input
              type="text"
              className="template__input"
              name="effective_coverage_date"
              value={formData && formData.effective_coverage_date}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Name of Employer Group:
            <input
              type="text"
              className="template__input"
              name="employer_group_name"
              value={formData && formData.employer_group_name}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Patient’s Name:
            <input
              type="text"
              className="template__input"
              name="patient_name"
              value={
                (formData ? formData.patient_name : "")
                  || (client ? `${client.firstName} ${client.lastName}` : "")
              }
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Patient’s Date of Birth:
            <input
              type="text"
              className="template__input"
              name="patient_dob"
              value={
                (formData ? formData.patient_dob : "")
                  || (client ? moment(client.birthDate).format('MM/DD/YYYY') : "")
              }
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <div className="template__field">
          <label className="template__label">
            Patient’s ID Number (if not the subscriber):
            <input
              type="text"
              className="template__input"
              name="patient_id_number"
              value={formData && formData.patient_id_number}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        <p className="template__text">
        Certification: I hereby certify that the information I have provided on this form is true and
        accurate. Any person who knowingly and with intent to defraud, injure, or deceive any
        insurance company submits a claim or application containing any materially false, deceptive,
        incomplete, or misleading information pertaining to such claim may be committing a fraudulent
        insurance act, which is a crime and may subject a person to criminal or civil penalties, including
        fines and/or imprisonment or denial of benefits. In the event benefit payments are incorrectly
        or improperly made, I shall be fully responsible for repayment
        to <input
              type="text"
              className="template__text-input"
              name="provider_name"
              value={
                (formData ? formData.provider_name : "")
                  || (currentUser.type === "PROVIDER" ? `${currentUser.firstName} ${currentUser.lastName}` : `${currentUser.provider.firstName} ${currentUser.provider.lastName}`)
              }
              onChange={(event) => handleChanges(event)}
            />
        , LCSW, CCS all
        costs, fees, and expenses related to such improper or incorrect benefit information.

        </p>
        <div className="template__field">
          <h3 className="template__label">
            Patient's signature:
          </h3>
          {signatureUrl ? (
            <div className="template__flex">
              <img
                src={signatureUrl}
                alt="signature"
                className="modal__signature-img"
              />
              <button
                type="button"
                className="template__signature-button"
                onClick={() => setObjectFormSignature(true)}
                title="Click to sign the formgit "
              >
                <img
                  src={images.edit}
                  alt="signature"
                />
              </button>
            </div>
          ) : (
            <div
              className="template__input"
              role="presentation"
              onClick={() => setObjectFormSignature(true)}
            >
              <button
                type="button"
                className="template__signature-button"
                onClick={() => setObjectFormSignature(true)}
              >
                <img
                  src={images.edit}
                  alt="signature"
                />
              </button>
            </div>
          )}
        </div>

        <div className="template__field">
          <label className="template__label">
            Date of Signature:
            <input
              type="text"
              className="template__input"
              name="date"
              defaultValue={moment()}
              value={formData && formData.date}
              onChange={(event) => handleChanges(event)}
            />
          </label>
        </div>
        {objectFormSignature && <Modal
          isOpen={objectFormSignature ? true : false}
          data={objectFormSignature}
          onClose={(data) => handleSignature(data)}
          name='form-signature'/>}
      </div>
    </Page>
  );
});
