import React from 'react';
const moment= require('moment');

export const ProvidersItem = ({ provider, handleTodosClick }) => {

  const handleTodos = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    handleTodosClick(provider);
  };

    return (
    <>
      <td className='clients__row-item'>
        <p className="clients__main-item">
          {provider.firstName} {provider.lastName}
          {provider.todoCount > 0 && (
            <span
              className="clients__row-marker"
              role="presentation"
              onClick={(event) => handleTodos(event)}
              title="You have active todo"
            >
              {provider.todoCount}
            </span>
          )}
        </p>
      </td>
      <td className='clients__row-item'>{moment(provider.registrationDate).format('MM/DD/YYYY')}</td>
      <td className='clients__row-item'>{provider.lastLogin ? moment(provider.lastLogin).format('MM/DD/YYYY') : '-'}</td>
    </>
  );
};
