import React, { useState, useEffect, useMemo }from 'react';
import { useSelector }from 'react-redux';
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';
import './style.css';

import { getSlots } from '../../../../helper';
import { updateSession } from '../../../../api/sessions';
import { getAllServices, getService } from '../../../../api/services';
import { getScheduleByDate } from '../../../../api/schedule';
import { selectors } from '../../../../redux/store';
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const moment = require('moment');

export const SessionEdit = ({ data, handleClose }) => {
  const currentUser = useSelector(selectors.getCurrentUser);
  const [selectedDate, setSelectedDate] = useState(data.sessionDate);
  const [isLoading, setIsLoading] = useState(false);
  const [services, setServices] = useState(null);
  const [session, setSession] = useState(data);
  const [schedule, setSchedule] = useState([]);
  const [service, setService] = useState({});

  const filteredServiceNames = useMemo(() => {
    if (services) {
      return [...new Set(services.map(item => item.serviceName))]
    }
  }, [services]);

  const filteredServiceTypes = useMemo(() => {
    if (service && services) {
      return (services.filter(s => s.serviceName === service.serviceName).map(item => item.serviceType))
    }
  }, [service, services]);

  const loadServices = async () => {
    try {
      const services = await(getAllServices());
      setServices(services);
    } catch (error) {
      console.log(error);
    };
  };

  const loadSchedule = async (id, date) => {
    try {
      const schedule = await(getScheduleByDate(id, moment(date).format('MM/DD/YYYY')));
      if(!schedule.length) return setSchedule([]);

      schedule.forEach(item => setSchedule(item.intervals));
    } catch (error) {
      console.log(error);
    };
  };

  const loadService = async (id) => {
    try {
      const service = await(getService(id));
      setService(service);
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    loadServices(currentUser.id);
    if ((moment(session.sessionDate).isAfter(moment())) || (moment(session.sessionDate).format('MM/DD/YYYY') === moment().format('DD-MM-YY'))) {
      loadSchedule(currentUser.id, session.sessionDate);
    }
    loadService(session.serviceId);
  }, [currentUser.id, session.sessionDate, session.serviceId]);

  const handleSlot = (event) => {
    setSession({
      ...session,
      sessionDate: moment(event.target.value).utc(true),
    });
  };

  const handleStatus = (event) => {
    setSession({
      ...session,
      sessionStatus: event.target.value,
    });
  };

  const handleService = async (event, serv) => {
    const selectedService = services
      .find(item => (item.serviceName === service.serviceName)
        && (item.serviceType === serv));
    try {
      const service = await(getService(selectedService.id));
      setService(service);
      setSession({
        ...session,
        sessionType: service.serviceName,
        serviceId: service.id,
        duration: service.duration,
      });
    } catch (error) {
      console.log(error);
    };
  };

  const handleServiceName = async (event) => {
    setService({
      ...service,
      [event.target.name]: event.target.value,
    });

    if ((event.target.value === 'Clinical supervision') || (event.target.value === 'Initial Evaluation')) {
      const selectedService = services
        .find(item => (item.serviceName === event.target.value));
      try {
        const service = await(getService(selectedService.id));
        setService(service);
        setSession({
          ...session,
          sessionType: service.serviceName,
          serviceId: service.id,
          duration: service.duration,
        });
      } catch (error) {
        console.log(error);
      };
    }
  };

  const onChange = (date) => {
    if (moment(date).isAfter(moment())) {
      setSelectedDate(date);
      setSession({
        ...session,
        sessionDate: date,
      });
      loadSchedule(currentUser.id, date);
    } else {
      setSchedule([]);
    };
  };

  const handleSubmit = async (event) => {
    try {
      setIsLoading(true);
      await(updateSession(session));
      setIsLoading(false);
      handleClose(null, session);
      console.log(session);
    } catch (error) {
      console.log(error);
    };
  };

  const slots = useMemo(() => (
    [...schedule].map(item => {
      return getSlots(
        moment(item.start),
        moment(item.end),
        service.duration,
        service.bufferTimeAfter,
      );
    })
  ), [service, schedule]);

  return (
    <>
        <div className="book__block">
          <h2 className="modal__secondary-title highlighted">
            Please select service
          </h2>
          <ul className="book__toggler-group">
            {filteredServiceNames && filteredServiceNames.map(item => (
              <li key={item} className="book__toggler-item">
                <input
                  type="checkbox"
                  id={item}
                  className="modal__toggler"
                  name="serviceName"
                  value={item}
                  onChange={(event) => handleServiceName(event, item)}
                  checked={service.serviceName === item}
                />
                <label htmlFor={item} className="modal__toggler-label">
                  {item}
                </label>
              </li>
            ))}
          </ul>
            {(service && service.serviceName && filteredServiceTypes[0]) && (
              <>
                <h3 className="modal__secondary-title">
                  Service type
                </h3>
                <ul className="book__toggler-group">
                  {filteredServiceTypes.map(item => (
                    <li key={item} className="book__toggler-item">
                      <input
                        type="checkbox"
                        id={item}
                        className="modal__toggler"
                        name="serviceType"
                        value={item}
                        onChange={(event) => handleService(event, item)}
                        checked={service.serviceType === item}
                      />
                      <label htmlFor={item} className="modal__toggler-label">
                        {item}
                      </label>
                    </li>
                  ))}
                </ul>
              </>
            )}
        </div>
        <div className="book__block">
          <h2 className="modal__secondary-title highlighted">
            {`Pick Date & Time`}
          </h2>
          <div className="modal__field">
            <h3 className="modal__secondary-title">
              Date
            </h3>
            <Calendar
              className="date-picker"
              onChange={(date) => onChange(date)}
              tileClassName="calendar-item"
              prev2Label={null}
              next2Label={null}
              locale="en"
              value={new Date(selectedDate)}
            />
          </div>
          <div className="modal__field">
            {slots.length === 0 ? (
              <p className="message">No avaliable time for chosen date. Pick another date or service type, please.</p>
            ) : (
              <>
                <h3 className="modal__secondary-title">
                  Time
                </h3>
                <ul className="book__toggler-group">
                  {slots.map(item => item.map(interval => (
                    <li key={Math.random()} className="book__toggler-item">
                      <input
                        id={interval.begin}
                        type="checkbox"
                        className="modal__toggler"
                        onChange={handleSlot}
                        checked={session.sessionDate && (session.sessionDate._i === interval.begin)}
                        value={interval.begin}
                      />
                      <label htmlFor={interval.begin} className="modal__toggler-label">
                      {`${moment(interval.begin).format('h:mm a')} - ${moment(interval.finish).format('h:mm a')}`}
                      </label>
                    </li>
                  )))}
                </ul>
              </>
            )}
          </div>
        </div>
        <div className="book__block">
          <h2 className="modal__secondary-title highlighted">
            Session status
          </h2>
          <Select
            type="text"
            id="status"
            onChange={handleStatus}
            value={session.sessionStatus}
            name="sessionStatus"
            className="modal__input"
            placeholder="Status"
          >
            <MenuItem value="" disabled>Choose status</MenuItem>
            <MenuItem value="SCHEDULED">Scheduled</MenuItem>
            <MenuItem value="CONFIRMED">Confirmed</MenuItem>
            <MenuItem value="HIDDEN">Hidden</MenuItem>
            <MenuItem value="MISSED">Missed</MenuItem>
          </Select>
        </div>
      <div className="modal__buttons">
        <button
          type="submit"
          className="modal__button book__submit-button"
          onClick={() => handleSubmit()}
          disabled={!(
            session.sessionDate
              && session.sessionType
              && session.client
          )}
        >
          {isLoading ? (
            <CircularProgress className="modal__button-progress" />
          ) : "Save"}
        </button>
      </div>
    </>
  );
};
