import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';

const initialState = {
  currentUser: null,

  query: '',

  clients: [],
  client: null,
  selectedClient: null,

  sessions: [],
  session: {},
  selectedSession: null,

  isAsideActive: false,
  isNotesActive: false,
  isDetailsActive: false,
  isUserActive: null,

  notes: [],
  note: {},

  forms: [],

  todos: [],
}

export const selectors = {
  getCurrentUser: state => state.currentUser,
  getQuery: state => state.query,

  getClients: state => state.clients,
  getClient: state => state.client,
  getSelectedClient: state => state.selectedClient,

  getSessions: state => state.sessions,
  getSession: state => state.session,
  getSelectedSession: state => state.selectedSession,

  getIsAsideActive: state => state.isAsideActive,
  getIsNotesActive: state => state.isNotesActive,
  getIsDetailsActive: state => state.isDetailsActive,
  getIsUserActive: state => state.isUserActive,

  getNotes: state => state.notes,
  getNote: state => state.note,

  getTodos: state => state.todos,
  getForms: state => state.forms,
}

const SET_USER = 'SET_USER';
const SET_QUERY = 'SET_QUERY';

const SET_CLIENTS = 'SET_CLIENTS';
const SET_CLIENT = 'SET_CLIENT';
const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';

const ADD_SESSION = 'ADD_SESSION';
const SET_SESSIONS = 'SET_SESSIONS';
const SET_SESSION = 'SET_SESSION';
const SET_SELECTED_SESSION = 'SET_SELECTED_SESSION';

const SET_IS_ASIDE_ACTIVE = 'SET_IS_ASIDE_ACTIVE';
const SET_IS_NOTES_ACTIVE = 'SET_IS_NOTES_ACTIVE';
const SET_IS_DETAILS_ACTIVE = 'SET_IS_DETAILS_ACTIVE';
const SET_IS_USER_ACTIVE = 'SET_IS_USER_ACTIVE';

const SET_NOTES = 'SET_NOTES';
const SET_NOTE = 'SET_NOTE';

const SET_TODOS = 'SET_TODOS';
const SET_FORMS = 'SET_FORMS';

export const actions = {
  setUser: user => ({
    type: SET_USER,
    user,
  }),

  setClients: clients => ({
    type: SET_CLIENTS,
    clients,
  }),
  setQuery: query => ({
    type: SET_QUERY,
    query,
  }),
  setClient: client => ({
    type: SET_CLIENT,
    client,
  }),

  setSelectedClient: selectedClient => ({
    type: SET_SELECTED_CLIENT,
    selectedClient,
  }),

  setSessions: sessions => ({
    type: SET_SESSIONS,
    sessions,
  }),
  setSession: session => ({
    type: SET_SESSION,
    session,
  }),
  addSession: () => ({
    type: ADD_SESSION,
  }),
  setSelectedSession: selectedSession => ({
    type: SET_SELECTED_SESSION,
    selectedSession,
  }),

  setIsAsideActive: bool => ({
    type: SET_IS_ASIDE_ACTIVE,
    bool,
  }),
  setIsNotesActive: bool => ({
    type: SET_IS_NOTES_ACTIVE,
    bool,
  }),
  setIsDetailsActive: bool => ({
    type: SET_IS_DETAILS_ACTIVE,
    bool,
  }),
  setIsUserActive: bool => ({
    type: SET_IS_USER_ACTIVE,
    bool,
  }),

  setNotes: notes => ({
    type: SET_NOTES,
    notes,
  }),
  setNote: note => ({
    type: SET_NOTE,
    note,
  }),

  setTodos: todos => ({
    type: SET_TODOS,
    todos,
  }),

  setForms: forms => ({
    type: SET_FORMS,
    forms,
  }),
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_USER:
      return {
        ...state,
        currentUser: action.user,
      };

    case SET_CLIENTS:
      return {
        ...state,
        clients: [...action.clients],
      };

    case SET_QUERY:
      return {
        ...state,
        query: action.query,
      };

    case SET_CLIENT:
      return {
        ...state,
        client: action.client,
      };

    case SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.selectedClient,
      };

    case SET_SESSION:
      return {
        ...state,
        session: action.session,
      };

    case ADD_SESSION:
      return {
        ...state,
        sessionsClone: [state.session, ...state.sessionsClone],
      };

    case SET_SELECTED_SESSION:
      return {
        ...state,
        selectedSession: action.selectedSession,
      };

    case SET_SESSIONS:
      return {
        ...state,
        sessions: action.sessions,
        sessionsClone: action.sessions,
      };

    case SET_IS_ASIDE_ACTIVE:
      return {
        ...state,
        isAsideActive: action.bool,
      };

    case SET_IS_NOTES_ACTIVE:
      return {
        ...state,
        isNotesActive: action.bool,
      };

    case SET_IS_DETAILS_ACTIVE:
      return {
        ...state,
        isDetailsActive: action.bool,
      };

    case SET_IS_USER_ACTIVE:
      return {
        ...state,
        isUserActive: action.bool,
      };

    case SET_NOTES:
      return {
        ...state,
        notes: action.notes,
      };

    case SET_NOTE:
      return {
        ...state,
        note: action.note,
      };

    case SET_TODOS:
      return {
        ...state,
        todos: action.todos,
      };

    case SET_FORMS:
      return {
        ...state,
        forms: action.forms,
      };

    default:
      return state;
  };
};

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(thunk),
    // other store enhancers if any
);
const store = createStore(reducer, enhancer);

export default store;
