import React from 'react';

const moment = require('moment');

export const FormsItem = ({ form }) => {

    return (
    <>
      <td className='clients__row-item'>
          {form.templateName || 'Unknown'}
      </td>
      <td className='clients__row-item'>
          {form.clientFullName}
      </td>
      <td className='clients__row-item'>
          {moment(form.creationDate).format('MM/DD/YYYY')}
      </td>
    </>
  );
};
