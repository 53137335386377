import React, { useState, useEffect } from 'react';
import Templates from './Templates';
import { images } from "../../helper";
import './style.css';

export const Modal = ({ name, isOpen, onClose,  ...rest }) => {
    const [opened, setOpened] = useState(isOpen);
    const componentObject = Templates.filter((componentArray) => componentArray.name === name)[0];
    const handleClose = (event, data = true) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        };

        setOpened(false);
        onClose(data);
    };
    console.log('opened', opened, isOpen);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div
            className="modal__background"
            style={{'display': isOpen ? "flex" : "none"}}
            onClick={(event) => handleClose(event)}
        >
            <div
                className="modal"
                onClick={(event) => event.stopPropagation(event)}
            >
                <div className="modal__header">
                    <h2 className="modal__title">
                        {componentObject.title}
                    </h2>
                    <button
                        type="button"
                        onClick={(event) => handleClose(event)}
                        className="modal__icon-button"
                    >
                        <img src={images.closer} alt="close" />
                    </button>
                </div>
                <div className="modal__content">
                    <componentObject.component handleClose={handleClose} {...rest}/>
                </div>
            </div>
        </div>
    );
};
