import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './style.css';

import { selectors } from '../../../../redux/store';
import { createNote } from '../../../../api/notes';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";

export const NoteCreate = ({data, handleClose}) => {
  const selectedClient = data.selectedClient;
  const selectedSession = data.selectedSession;
  const currentUser = useSelector(selectors.getCurrentUser);
  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState({
    clientId: selectedClient && selectedClient.id,
    sessionId: selectedSession && selectedSession.id,
    providerId: currentUser.id,
  });

  const handleChanges = (event) => {
    setNote({
      ...note,
      text: event.target.value,
    });
  };

  const handleType = (event) => {
    setNote({
      ...note,
      type: event.target.value,
    });
    console.log(note);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setIsLoading(true);
      await(createNote(note));
      setIsLoading(false);
      handleClose();
    } catch (error) {
      console.log(error)
    };
  };

  const types = ['Assessment', 'Group', 'Individual', 'Treatment Plan', 'Informational']

  return (
    <>
      <textarea
        className="note__textarea"
        onChange={handleChanges}
        value={note.text}
      ></textarea>

      <div className="modal__field">
        <label
          htmlFor="gender"
          className="modal__secondary-title"
        >
          Choose note type
        </label>
        <Select
          className="modal__select"
          onChange={handleType}
          title="Choose note group"
          id="group"
        >
            {(note.sessionId ? types.slice(0, 3) : types).map(type => (
            <MenuItem
              key={type}
              value={type}
            >
              {type}
            </MenuItem>
          ))}
        </Select>
      </div>

      <div className="modal__buttons">
        <button
          type="button"
          className="modal__button"
          onClick={handleSubmit}
          disabled={!note.text}
        >
          {isLoading ? (
            <CircularProgress className="modal__button-progress" />
          ) : "Save"}
        </button>
      </div>
    </>
  );
};
