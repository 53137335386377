import React  from 'react';

import './Authentication.css';
import { images } from '../../helper';
import {
    Redirect,
    Route,
} from "react-router-dom";
import {getToken} from "../../api/token";

export const NonAuthorizeContainer = ({ component: Component, ...rest }) => {
    const token = getToken();

    return (
        <div className="auth__container sign-in">
            <div className="auth__circle auth__circle-1"></div>
            <div className="auth__circle auth__circle-2"></div>
            <div className="auth__circle auth__circle-3"></div>
            <section className="auth__logo-container">
                <div className="auth__logo">
                    <img src={images.logo_white} alt="logo" />
                    <h2 className="auth__logo-title">
                        iPractice
                    </h2>
                    <p className="auth__logo-text">
                        Clinic service
                    </p>
                </div>
            </section>
            <Route {...rest} render={props => (
                !token
                    ? <Component {...props} />
                    : <Redirect to='/dashboard' />
            )} />
        </div>
    );
};
