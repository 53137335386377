import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getMonthData } from '../../../helper';
import { selectors } from '../../../redux/store';
import { Modal } from '../../Modal';

const moment = require('moment');

export const SchedulingMonth = ({ date, setObjectSessionCreate, setObjectSession, sessions }) => {
  const currentUser = useSelector(selectors.getCurrentUser);
  const [objectMessage, setObjectMessage] = useState(null);
  const weekDayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const month = getMonthData(date);

  const filteredSessions = (day) => {
    console.log(sessions.filter(session => session.sessionDate === moment(day).format()));
    return sessions.filter(session => moment(session.sessionDate).format('MM DD YYYY') === moment(day).format('MM DD YYYY'));
  };

  const handleDayClick = (day) => {
    setObjectSessionCreate({date: day, client: null});
  };

  const handleSessionClick = (event, session) => {
    event.preventDefault();
    event.stopPropagation();

    setObjectSession(session);
  };

  return (
    <table className="scheduling">
      <thead className="scheduling__head">
        <tr className="scheduling__week-days">
          {weekDayNames.map((day, index) => (
            <td
              key={day}
              value={index}
              className="scheduling__week-item"
            >
              {day}
            </td>
          ))}
        </tr>
      </thead>

      <tbody className="scheduling__body">
        {month.map((week, index) => (
          <tr key={index} className="scheduling__week">
            {week.map((day, index) => day !== undefined ? (
              <td
                key={index}
                className={
                  (day.format('DD-MM-YY') === (moment().format('DD-MM-YY')) && "scheduling__day scheduling__day--current scheduling__item")
                    || (day.isBefore(moment()) && "scheduling__day--disabled scheduling__item")
                    || (day.isAfter(moment()) && "scheduling__day scheduling__item")
                }
                onClick={day.isAfter(moment())
                  && (currentUser.type === 'PROVIDER' || (currentUser.type === 'CLIENT'
                    && (currentUser.formsVerified && currentUser.paymentTypeVerified)))
                      ? (() => handleDayClick(day))
                      : (() => setObjectMessage(`The session can be booked no later than a day before its start.
                      You can't book the session untill forms and payment type are verified`))
                }
              >
                {day.format('DD')}
                <ul className="scheduling__sessions-list">
                  {filteredSessions(day).map(session => (
                    <li
                      key={session.id}
                      className={(session.sessionStatus === 'SCHEDULED' || !session.sessionStatus)
                        ? "scheduling__session"
                        : session.sessionStatus === 'CONFIRMED'
                          ? "scheduling__session scheduling__session--green"
                          : "scheduling__session scheduling__session--red"
                      }
                      onClick={(event) => handleSessionClick(event, session)}
                    >
                      <span className="highlighted">
                        {moment(session.sessionDate).format('h:mm')}
                      </span>
                      {currentUser.type === 'PROVIDER'
                        ? ` ${session.client.firstName} ${session.client.lastName}`
                        : ` ${session.provider.firstName} ${session.provider.lastName}`}
                    </li>
                  ))}

                </ul>
              </td>
            ) : (
              <td key={index} className="scheduling__day--disabled"></td>
            ))}
          </tr>
        ))}
      </tbody>

      {objectMessage && <Modal
        isOpen={objectMessage ? true : false}
        data={objectMessage}
        onClose={() => setObjectMessage(null)}
        name='message'/>}
    </table>
  );
};
