import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/en-au';

import { selectors } from '../../../../redux/store';
import { errorClass } from '../../../../helper';
import { inviteUser, createUser } from '../../../../api/users';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

moment.locale('en-au');

export const ClientCreate = ({ handleClose }) => {
  const currentUser = useSelector(selectors.getCurrentUser);
  const [client, setClient] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState({email: '', phoneNumber: '',});

  const locale = 'en-au';

  const handleChanges = (event) => {
    if (event.target.value === "true") {
      setClient({
        ...client,
        [event.target.name]: true,
      });
    } else if (event.target.value === "false") {
      setClient({
        ...client,
        [event.target.name]: false,
      });
    } else {
      setClient({
        ...client,
        [event.target.name]: event.target.value,
      });
    };
    validateField(event.target.name, event.target.value);
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let emailValid = isEmailValid;
    let phoneValid = isPhoneValid;
    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
        break;
      case 'phoneNumber':
        phoneValid = value.match(/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm);
        fieldValidationErrors.phoneNumber = phoneValid ? '' : 'Phone number is invalid';
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setIsEmailValid(emailValid);
    setIsPhoneValid(phoneValid);
    if (emailValid) {
      setIsFormValid(true);
    };
  };

  const handleDateChanges = (date) => {
    setClient({
      ...client,
      birthDate: date,
    });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await(createUser(currentUser.id, client));
      handleClose(null, client);
    } catch (error) {
      console.log(error);
    };
    setIsLoading(false);
  };

  const handleInvite = async (event) => {
    try {
      setIsLoading(true);
      await(inviteUser(currentUser.id, client));
    } catch (error) {
      console.log(error);
    };
    setIsLoading(false);
    handleClose(event, client);
  };

  return (
   <>
      <div className="modal__two-fields">
        <div className="modal__field">
          <label
            htmlFor="name"
            className="modal__secondary-title"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            onChange={handleChanges}
            value={client.firstName}
            name="firstName"
            placeholder="Name"
            className="modal__input"
          />
        </div>
        <div className="modal__field">
          <label
            htmlFor="lastname"
            className="modal__secondary-title"
          >
            Lastname
          </label>
          <input
            type="text"
            id="lastname"
            onChange={handleChanges}
            value={client.lastName}
            name="lastName"
            placeholder="Lastname"
            className="modal__input"
          />
        </div>
      </div>
      <div className="modal__field">
        <label
          htmlFor="address"
          className="modal__secondary-title"
        >
          Address
        </label>
        <input
          type="text"
          id="address"
          onChange={handleChanges}
          value={client.address}
          placeholder="Address"
          name="address"
          className="modal__input"
        />
      </div>
      <div className="modal__field">
        <label
          htmlFor="phone"
          className="modal__secondary-title"
        >
          Phone
        </label>
        <input
          type="phone"
          id="phone"
          onChange={handleChanges}
          value={client.phoneNumber}
          name="phoneNumber"
          placeholder="Phone"
          className={`modal__input ${errorClass(formErrors.phoneNumber)}`}
        />
        <p class="error-message">
          {formErrors.phoneNumber}
        </p>
      </div>
      <div className="modal__field">
        <label
          htmlFor="email"
          className="modal__secondary-title"
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          onChange={handleChanges}
          value={client.email}
          name="email"
          placeholder="Email"
          className={`modal__input ${errorClass(formErrors.email)}`}
        />
        <p class="error-message">
          {formErrors.email}
        </p>
      </div>
      <div className="modal__two-fields">
        <div className="modal__field">
          <label
            htmlFor="birth"
            className="modal__secondary-title"
          >
            Birth Date
          </label>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale={locale}
            libInstance={moment}
          >
            <KeyboardDatePicker
              id="birth"
              onChange={handleDateChanges}
              value={client.birthDate}
              name="birthDate"
              className="modal__datepicker"
              variant="inline"
              format="MM/DD/yyyy"
              disableToolbar
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="modal__field">
          <label
            htmlFor="gender"
            className="modal__secondary-title"
          >
            Gender
          </label>
          <Select
            type="text"
            id="gender"
            onChange={handleChanges}
            value={client.gender}
            name="gender"
            className="modal__input"
            placeholder="Gender"
          >
            <MenuItem value="" disabled>Choose gender</MenuItem>
            <MenuItem value="MALE">Male</MenuItem>
            <MenuItem value="FEMALE">Female</MenuItem>
          </Select>
        </div>
      </div>
      <div className="modal__two-fields">
        <div className="modal__field">
          <label
            htmlFor="insurance-name"
            className="modal__secondary-title"
          >
            Insurance company name
          </label>
          <input
            type="text"
            id="insuranse-name"
            onChange={handleChanges}
            name="info"
            value={client.info}
            placeholder="Company"
            className="modal__input"
          />
        </div>
        <div className="modal__field">
          <label
            htmlFor="insurance-code"
            className="modal__secondary-title"
          >
            Insurance code
          </label>
          <input
            type="text"
            id="insuranse-code"
            onChange={handleChanges}
            name="code"
            value={client.code}
            placeholder="Code"
            className="modal__input"
          />
        </div>
      </div>
      <div className="modal__field">
        <label
          htmlFor="diagnosis"
          className="modal__secondary-title"
        >
          Diagnosis Code
        </label>
        <input
          type="text"
          id="diagnosis"
          onChange={handleChanges}
          value={client.diagnosis}
          name="diagnosis"
          placeholder="Diagnosis"
          className="modal__input"
        />
      </div>
      <div className="modal__field">
        <label
          htmlFor="refferal"
          className="modal__secondary-title"
        >
          Referral type
        </label>
        <input
          type="text"
          id="referal"
          onChange={handleChanges}
          value={client.referralType}
          name="referralType"
          placeholder="Refferal Type"
          className="modal__input"
        />
      </div>
      <div className={client.paymentTypeVerified === 'COPAY' && "modal__two-fields"}>
        <div className="modal__field">
          <label
            htmlFor="payment"
            className="modal__secondary-title"
          >
            Payment type
          </label>
          <Select
            type="text"
            id="payment"
            onChange={handleChanges}
            value={client.paymentTypeVerified}
            name="paymentTypeVerified"
            className="modal__input"
            placeholder="Payment"
          >
            <MenuItem value="" disabled>Choose payment</MenuItem>
            <MenuItem value="INSURANCE">Insurance</MenuItem>
            <MenuItem value="COPAY">CoPay</MenuItem>
            <MenuItem value="SELFPAY">Selfpay</MenuItem>
            <MenuItem value="UNSET">Unset</MenuItem>
          </Select>
        </div>
        {client.paymentTypeVerified === "COPAY" && (
        <div className={(!client.coPay || (client.coPay <= 0)) ? "modal__field modal__field--error" : "modal__field"}>
          <label
              htmlFor="copay"
              className="modal__secondary-title"
            >
              CoPay
            </label>
            <input
              type="text"
              id="copay"
              onChange={handleChanges}
              value={client.coPay}
              name="coPay"
              className="modal__input"
              placeholder="Copay amount"
            />
          </div>
        )}
        
      </div>
      <div className="modal__field">
        <label
          htmlFor="forms"
          className="modal__secondary-title"
        >
          Forms verified
        </label>
        <div className="modal__checkbox">
          <label
            htmlFor="forms-yes"
            className="modal__secondary-title"
          >
            Yes
          </label>
            <input
              type="radio"
              id="forms-yes"
              onChange={handleChanges}
              value={true}
              name="formsVerified"
              checked={client.formsVerified}
              className="modal__input"
            />
          <label
            htmlFor="forms-no"
            className="modal__secondary-title"
          >
            No
          </label>
          <input
              type="radio"
              id="forms-no"
              onChange={handleChanges}
              value={false}
              name="formsVerified"
              checked={!client.formsVerified}
              className="modal__input"
            />
        </div>
      </div>
      <div className="modal__buttons">
        <button
          type="button"
          className="modal__button"
          onClick={() => handleSave()}
          disabled={!isFormValid}
        >
          {isLoading ? (
            <CircularProgress className="modal__button-progress"/>
          ) : 'Save'}
        </button>
        <button
          type="submit"
          className="modal__button"
          onClick={(event) => handleInvite(event)}
          disabled={!isFormValid}
        >
          Send Invite
        </button>
      </div>
    </>
  );
};