import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.css';

import CircularProgress from "@material-ui/core/CircularProgress";
import { images } from '../../helper';
import { selectors, actions } from '../../redux/store';
import { ClientsList } from './List';
import { getAllUsers } from '../../api/users';
import { Modal } from '../Modal';

export const Clients = () => {
    const [objectClientCreate, setObjectClientCreate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const clients = useSelector(selectors.getClients);
    const query = useSelector(selectors.getQuery);
    const isUserActive = useSelector(selectors.getIsUserActive);
    const dispatch = useDispatch();

    useEffect(() => {
        loadData(dispatch);
    }, [dispatch]);

    const loadData = async (disp) => {
        setIsLoading(true);
        try {
            setError(null);
            const clientsData = await(getAllUsers());
            console.log(clientsData);
            disp(actions.setClients(clientsData));
        } catch (error) {
            setError(error);
            console.log(error);
        };
        setIsLoading(false);
    };

    const handleChanges = (event) => {
        dispatch(actions.setQuery(event.target.value));
    };

    const handleUpdate = (data) => {
        if (data) {
          loadData(dispatch);
        };
    
        setObjectClientCreate(null);
      };

    return (
        <>
            <section className="header">
                <div className="header__left">
                    <div className="header__pic">
                        <img src={images.clients_blue} alt="clients" />
                    </div>
                    <h1 className="header__title">
                        Clients
                        <span className="header__clients-count highlighted">
                            {clients.length}
                        </span>
                    </h1>

                </div>
                <div className="header__right">
                    <button
                        type="button"
                        className="header__button flex-button"
                        onClick={() => setObjectClientCreate(true)}
                        title="Add new client"
                    >
                        <img src={images.add} alt="add client" className="flex-button__img" />
                        <p className="flex-button__text">
                            Add client
                        </p>
                    </button>
                </div>
            </section>

            <section className="clients__nav">
                <input
                    className="header__search"
                    type="text"
                    placeholder="Search"
                    value={query}
                    onChange={handleChanges}
                />
            </section>
            {isLoading ? (
                <CircularProgress className="progress" />
            ) :
                error ? (
                    <p className="clients__message">Not Found</p>
                ) :
                    clients.length ? (
                        <ClientsList loadData={loadData}/>
                    ) : (
                        <p className="clients__message">You don't have clients yet</p>
            )}
            
            {objectClientCreate && <Modal
                isOpen={objectClientCreate ? true : false}
                data={objectClientCreate}
                onClose={(data) => handleUpdate(data)}
                name='client-create'/>}

            {isUserActive && <Modal
                isOpen={isUserActive ? true : false}
                data={isUserActive}
                onClose={() => dispatch(actions.setIsUserActive(null))}
                name='profile'/>}
        </>
    );
};
