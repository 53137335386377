import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './style.css';

import { selectors } from '../../../../redux/store';
import { getUser, deleteUser, inviteUser } from '../../../../api/users';
import { getClientSessions } from '../../../../api/sessions';
import { images } from '../../../../helper';
import { Modal } from '../../../Modal';
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAllFormsByClient } from '../../../../api/forms';

const moment= require('moment');

export const Client = ({ data, handleClose }) => {
  const currentUser = useSelector(selectors.getCurrentUser);
  const [selectedClient, setSelectedClient] = useState(data);
  const [sessions, setSessions] = useState(null);
  const [forms, setForms] = useState(null);
  const [objectForm, setObjectForm] = useState(null);
  const [objectSession, setObjectSession] = useState(null);
  const [objectClientEdit, setObjectClientEdit] = useState(null);
  const [objectSessionCreate, setObjectSessionCreate] = useState(null);
  const [objectConfirm, setObjectConfirm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(null);

  const loadUser = async (id, close) => {
    setIsLoading(true);

    try {
      const user = await(getUser(id));
      setSelectedClient(user);
    } catch (error) {
      console.log(error);
      close(null, null);
    };
    setIsLoading(false);
  };

  const loadSessions = async (clientId, providerId, close) => {
    setIsLoading(true);
  
    try {
      const clientSessions = await(getClientSessions(clientId, providerId));
      setSessions(clientSessions);
    } catch (error) {
      console.log(error);
      close(null, null);
    };
    setIsLoading(false);
  };

  const loadForms = async (providerId, clientId, close) => {
    setIsLoading(true);

    try {
      const clientForms = await(getAllFormsByClient(providerId, clientId));
      setForms(clientForms);
    } catch (error) {
      console.log(error);
      close(null, null);
    };
    setIsLoading(false);
  };

  useEffect(() => {
    loadUser(selectedClient.id, handleClose);
    loadSessions(selectedClient.id, currentUser.id, handleClose);
    loadForms(currentUser.id, selectedClient.id, handleClose)
  }, [selectedClient.id, currentUser.id, handleClose]);

  const removeClient = async (id) => {
    setIsLoading(true);

    try {
      await(deleteUser(id));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      handleClose(null, id);
    };
  };

   const handleUpdate = (data) => {
    if (data) {
      loadUser(selectedClient.id);
      loadSessions(selectedClient.id, currentUser.id);
    };

    setObjectClientEdit(null);
    setObjectSessionCreate(null);
    setObjectForm(null);
    setObjectSession(null);
  };

  const handleConfirm = (data) => {
    if (data) {
      removeClient(selectedClient.id);
    };

    setObjectConfirm(null);
  };

  const handleInvite = async (event) => {
    try {
      setIsLoading(true);
      await(inviteUser(currentUser.id, selectedClient));
    } catch (error) {
      console.log(error);
    };
    setIsLoading(false);
    handleClose(event, selectedClient);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress className="progress" />
      ) : (
        <div className="client__info">
          <div className="client__image-container">
            <img src={images.profile} alt="client" className="client__image"/>
          </div>
          <div className="client__list">
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Name
              </h3>
              <p className="client__info-text modal__text">{selectedClient.firstName}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Lastname
              </h3>
              <p className="client__info-text modal__text">{selectedClient.lastName}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Phone
              </h3>
              <p className="client__info-text modal__text">{selectedClient.phoneNumber}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Email
              </h3>
              <p className="client__info-text modal__text">{selectedClient.email}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Address
              </h3>
              <p className="client__info-text modal__text">{selectedClient.address}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Birth date
              </h3>
              <p className="client__info-text modal__text">{moment(selectedClient.birthDate).format('MM/DD/YYYY')}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Gender
              </h3>
              <p className="client__info-text modal__text">{selectedClient.gender}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Referral type
              </h3>
              <p className="client__info-text modal__text">{selectedClient.referralType}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Insurance company name
              </h3>
              <p className="client__info-text modal__text">{selectedClient.info}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Insurance code
              </h3>
              <p className="client__info-text modal__text">{selectedClient.code}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Diagnosis code
              </h3>
              <p className="client__info-text modal__text">{selectedClient.diagnosis}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Payment type
              </h3>
              <p className="client__info-text modal__text">
                {selectedClient.paymentTypeVerified === 'COPAY'
                  ? `${selectedClient.paymentTypeVerified} (${(selectedClient.coPay || '0')}$)`
                  : selectedClient.paymentTypeVerified}
              </p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Forms
              </h3>
              <p className="client__info-text modal__text">
                {selectedClient.formsVerified ? ('Verified') : ('Not verified')}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="modal__buttons">
        <div>
          <button
            className="modal__button"
            type="button"
            disabled={selectedClient.isRegistred}
            onClick={handleInvite}
          >
            Resend Invite
          </button>
          <button
            className="modal__button"
            type="button"
            onClick={() => setObjectSessionCreate({date: null, client: selectedClient})}
          >
            Book a Session
          </button>
        </div>

        <div>
          <button
            className="modal__icon-button"
            type="button"
            onClick={() => setObjectConfirm({message: `delete ${selectedClient.firstName} ${selectedClient.lastName}`})}
            title="Delete"
          >
            <img
              src={images.trash_bin}
              alt="delete"
            />
          </button>
          <button
            className="modal__icon-button"
            type="button"
            onClick={() => setObjectClientEdit(selectedClient)}
            title="Edit"
          >
            <img
              src={images.edit}
              alt="edit"
            />
          </button>
        </div>
      </div>

      <div className="modal__tabs">
        <div
          className={
            tab === 'sessions'
              ? "modal__tabs-item modal__tabs-item--checked"
              : "modal__tabs-item"
          }
          onClick={() => setTab('sessions')}
        >
          Sessions
        </div>
        <div
          className={
            tab === 'forms'
              ? "modal__tabs-item modal__tabs-item--checked"
              : "modal__tabs-item"
          }
          onClick={() => setTab('forms')}
        >
          Forms
        </div>
      </div>

      {tab === "sessions" && (
        sessions && sessions.length > 0 ? (
          <table className="client__sessions">
            <tbody>
              {sessions.map(session => (
                <tr
                  className="client__session"
                  key={session.id}
                  onClick={() => {
                    setObjectSession(session);
                  }}
                >
                  <td className="client__session-item">{session.billingStatus}</td>
                  <td className="client__session-item">{`${moment(session.sessionDate).format('MM/DD/YYYY')}  ${moment(session.sessionDate).format('h:mm')}`}</td>
                  <td className="client__session-item">{session.sessionType}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="message">No sessions</p>
        )
      )}

      {tab === "forms" && (
        forms && forms.length > 0 ? (
          <table className="client__sessions">
            <tbody>
              {forms.map(form => (
                <tr
                  className="client__session"
                  key={form.id}
                  onClick={() => {
                    setObjectForm(form);
                  }}
                >
                  <td className="client__session-item">{form.templateName}</td>
                  <td className="client__session-item">{form.clientFullName}</td>
                  <td className="client__session-item">{moment(form.creationDate).format('MM/DD/YYYY')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="message">No forms</p>
        )
      )}

      {objectClientEdit && <Modal
        isOpen={objectClientEdit ? true : false}
        data={objectClientEdit}
        onClose={(data) => handleUpdate(data)}
        name='client-edit'/>}

      {objectSessionCreate && <Modal
        isOpen={objectSessionCreate ? true : false}
        data={objectSessionCreate}
        onClose={(data) => handleUpdate(data)}
        name='session-create'/>}

      {objectForm && <Modal
        isOpen={objectForm ? true : false}
        data={objectForm}
        onClose={(data) => handleUpdate(data)}
        name='form'/>}

      {objectSession && <Modal
        isOpen={objectSession ? true : false}
        data={objectSession}
        onClose={(data) => handleUpdate(data)}
        name='session'/>}

      {objectConfirm && <Modal
        isOpen={objectConfirm ? true : false}
        data={objectConfirm}
        onClose={(data) => handleConfirm(data)}
        name='confirm'/>}
    </>
  );
};
