import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './style.css';

import { selectors, actions } from '../../../redux/store';
import { SessionsItem } from '../Item';
import { Modal } from "../../Modal";
import { Pagination } from '../../Pagination';

export const SessionsList = ({loadSessions}) => {
  const sessions = useSelector(selectors.getSessions);
  const isUserActive = useSelector(selectors.getIsUserActive);
  const currentUser = useSelector(selectors.getCurrentUser);
  const query = useSelector(selectors.getQuery);
  const [objectNotes, setObjectNotes] = useState(null);
  const [objectTodos, setObjectTodos] = useState(null);
  const [objectSession, setObjectSession] = useState(null);
  const [sort, setSort] = useState('id');
  const [page, setPage] = useState(1);
  const [pageStart, setPageStart] = useState(0);
  const [pageEnd, setPageEnd] = useState(10);
  const dispatch = useDispatch();

  const sortedSessions = useMemo(() => {
    return ([...sessions]
      .filter(session => (session.client && (session.client.lastName + session.client.firstName + session.sessionType + session.sessionDate).toString().toLowerCase()
              .includes(query.toString().toLowerCase().replace(/ +/g, '')))
      ))
      // eslint-disable-next-line
      .sort((a, b) => {
        if (a[sort]) {
          if (isNaN(a[sort])) {
            return a[sort].localeCompare(b[sort]);
          };

          return a[sort] - b[sort];
        };
      });
  }, [sessions, sort, query]);

  const handleNotes = (session, client) => {
    setObjectNotes({client, session});
  };

  const handleTodos = (session) => {
    setObjectTodos({client: null, session});
  };

  const handleUpdate = (data) => {
    if (data) {
      loadSessions(dispatch);
    };

    setObjectSession(null);
    setObjectNotes(null);
    setObjectTodos(null);
  };

  return (
    <>
      <table className="sessions__table">
        <thead>
            <tr className='sessions__row-first sessions__row' title="Sort">
              {currentUser.type === 'PROVIDER' ? (
              <th className='sessions__row-item' onClick={() => setSort('client.firstName')}>Client Name</th>
              ) : (
                <th className='sessions__row-item' onClick={() => setSort('provider.firstName')}>Provider Name</th>
              )}
              <th className='sessions__row-item' onClick={() => setSort('sessionDate')}>Date</th>
              <th className='sessions__row-item' onClick={() => setSort('sessionDate')}>Time</th>
              <th className='sessions__row-item' onClick={() => setSort('sessionType')}>Session Type</th>
              <th className='sessions__row-item' onClick={() => setSort('sessionStatus')}>Status</th>
              {currentUser.type === 'PROVIDER' && (
                <th className='sessions__row-item'>Notes</th>
              )}
            </tr>
        </thead>
        <tbody>
            {sortedSessions.slice(pageStart, pageEnd).map(session => (
              <tr
                key={session.id}
                className={session.billingStatus === 'UNBILLED' ? "sessions__row sessions__row--not-active" : "sessions__row"}
                onClick={() => setObjectSession(session)}
                title={session.billingStatus}
              >
                <SessionsItem handleNotesClick={handleNotes} handleTodosClick={handleTodos} session={session} />
              </tr>
            ))}
          </tbody>
        </table>

        {sortedSessions && (
          <Pagination 
            page={page}
            setPage={setPage}
            itemsQuantity={sortedSessions.length}
            pageStart={pageStart}
            setPageStart={setPageStart}
            pageEnd={pageEnd}
            setPageEnd={setPageEnd}
          />
        )}

        {objectNotes && <Modal
          isOpen={objectNotes ? true : false}
          data={objectNotes}
          onClose={(data) => handleUpdate(data)}
          name='notes'/>}

        {objectSession && <Modal
          isOpen={objectSession ? true : false}
          data={objectSession}
          onClose={(data) => handleUpdate(data)}
          name='session'/>}

        {objectTodos && <Modal
          isOpen={objectTodos ? true : false}
          data={objectTodos}
          onClose={(data) => handleUpdate(data)}
          name='todos'/>}
          
        {isUserActive && <Modal
          isOpen={isUserActive ? true : false}
          data={isUserActive}
          onClose={() => dispatch(actions.setIsUserActive(null))}
          name='profile'/>}
    </>
  );
};
