import { request, mltipartRequest } from './api';

export const getAllForms = () => {
  return request(`/form/find-all`, {});
};

export const getAllFormsByClient = (proiderId, clientId) => {
  return request(`/form/find-by-provider-and-client?client_id=${clientId}&provider_id=${proiderId}`, {});
};

export const createForm = (form) => {
  return request(`/form`, {
    method: 'POST',
    body: JSON.stringify(form),
  });
};

export const createFormPDF = (form, blob) => {
  const formData = new FormData();

  formData.append("file", blob, `${form.templateName || 'file'}.pdf`);

  return mltipartRequest(`/form/send-pdf?form_id=${form.id}`, {
    method: 'POST',
    body: formData,
  });
};

export const updateForm = (form) => {
  return request(`/form`, {
    method: 'PUT',
    body: JSON.stringify(form),
  });
};

export const getForm = (providerId, clientId, formName) => {
  return request(`/form/find-not-signed?name=${formName}&client_id=${clientId}&provider_id=${providerId}`, {});
};

export const getFormById = (id) => {
  return request(`/form/${id}`, {});
};

export const removeForm = (id) => {
  return request(`/form/${id}`, {
    method: 'DELETE',
  });
};
