import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './style.css';

import { selectors } from '../../../../redux/store';
import { images, stringNormalise } from '../../../../helper';
import { getAllNotes, getNote, removeNote } from '../../../../api/notes';
import { Modal } from '../..';
import CircularProgress from "@material-ui/core/CircularProgress";

const moment= require('moment');

export const Notes = ({data, handleClose}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [objectNote, setObjectNote] = useState(null);
  const [objectNotesCreate, setObjectNotesCreate] = useState(null);
  const [notes, setNotes] = useState(null);
  const [objectConfirm, setObjectConfirm] = useState(null);
  const selectedClient = data.client;
  const selectedSession = data.session;
  const currentUser = useSelector(selectors.getCurrentUser);

  const loadNotes = async (id, client, session) => {
    try {
      setIsLoading(true);
      const notes = await(getAllNotes(id));
      setNotes(notes
          .filter(note => {
            if (client) {
              return (note.clientId === client.id);
            };

            if (client && session) {
              return ((note.clientId === client.id) && (note.sessionId === session.id));
            };

            return (note.sessionId === session.id);
          }));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    loadNotes(currentUser.id, selectedClient, selectedSession);
  }, [currentUser.id, selectedClient, selectedSession]);

  const handleUpdate = (data) => {
    if (data) {
      loadNotes(currentUser.id, selectedClient, selectedSession);
    };

    setObjectNote(null);
    setObjectNotesCreate(null);
  };

  const handleNote = async (note) => {
    try {
      const noteData = await(getNote(note.id));
      setObjectNote(noteData);
    } catch (error) {
      console.log(error)
    };
  };

  const remove = async (noteId) => {
    try {
      await(removeNote(noteId));
    } catch (error) {
      console.log(error);
    } finally {
      loadNotes(currentUser.id, selectedClient, selectedSession);
    };
  };

  const handleConfirm = (data) => {
    remove(data);
    setObjectConfirm(null);
  };

  const handleDelete = async (event, noteId) => {
    event.preventDefault();
    event.stopPropagation();

    setObjectConfirm({message: 'delete this note', id: noteId});
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress className="progress" />
      ) : (!notes || !notes.length) ? (
        <p className="message">You don't have notes yet</p>
        ) : (
          <table className="notes">
            <tbody>
              {notes.map(note => (
                <tr
                  className="notes__row"
                  onClick={() => handleNote(note)}
                  key={note.id}
                >
                  <td className="notes__row-item">
                    <p>
                      {`${note.text.slice(0, 15)}...`}
                    </p>
                    <p className="highlighted">
                      {`Created: ${moment(note.creationDate).format('MM/DD/YYYY') || '-'}`}
                    </p>
                  </td>
                  <td className="notes__row-item">
                    <p className="highlighted">
                      {stringNormalise(note.type)}
                    </p>
                  </td>
                  <td className="notes__row-item">
                    <button
                      className="notes__row-button"
                      title="Delete"
                      onClick={(event) => handleDelete(event, note.id)}
                    >
                      <img src={images.trash_bin} alt="delete" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      )}
      <button
        className="modal__button"
        onClick={() => setObjectNotesCreate({selectedClient, selectedSession})}
      >
        Create note
      </button>

      {objectNote && <Modal
        isOpen={objectNote ? true : false}
        data={objectNote}
        onClose={(data) => handleUpdate(data)}
        name='note'/>}

      {objectNotesCreate && <Modal
        isOpen={objectNotesCreate ? true : false}
        data={objectNotesCreate}
        onClose={(data) => handleUpdate(data)}
        name='note-create'/>}

      {objectConfirm && <Modal
        isOpen={objectConfirm ? true : false}
        data={objectConfirm}
        onClose={(data) => handleConfirm(data)}
        name='confirm'/>}
    </>
  );
};
