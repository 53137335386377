import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './style.css';

import { images } from '../../helper';
import { selectors, actions } from '../../redux/store';
import { getAllSessions } from '../../api/sessions';
import { Modal } from '../Modal';
import { SchedulingMonth } from './Month';
// import { SchedulingWeek } from './Week';
// import { SchedulingDay } from './Scheduling_Day';

const moment = require('moment');

export const Scheduling = () => {
  // eslint-disable-next-line
  const [toggler, setToggler] = useState("month");
  const [date, setDate] = useState(moment());
  const [sessions, setSessions] = useState([]);
  const currentUser = useSelector(selectors.getCurrentUser);
  const isUserActive = useSelector(selectors.getIsUserActive);
  const [objectSessionCreate, setObjectSessionCreate] = useState(null);
  const [objectSession, setObjectSession] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    loadSessions();
  }, []);

  const loadSessions = async () => {
    try {
      const sessions = await(getAllSessions());
      setSessions(sessions);
      console.log(sessions);
    } catch (error) {
      console.log(error);
    };
  };

  const handlePrev = () => {
    switch (toggler) {
      case 'week':
        setDate(moment(date.subtract(7, 'days')));
        break;
      case 'day':
        setDate(moment(date.subtract(1, 'days')));
        break;
      default:
        setDate(moment(date.subtract(1, 'month')));
    };
  };

  const handleNext = () => {
    switch (toggler) {
      case 'week':
        setDate(moment(date.add(7, 'days')));
        break;
      case 'day':
        setDate(moment(date.add(1, 'days')));
        break;
      default:
        setDate(moment(date.add(1, 'month')));
      };
  };

  const handleUpdate = (data) => {
    if (data) {
      loadSessions(currentUser.id);
    };

    setObjectSessionCreate(null);
    setObjectSession(null);
  };

  return (
    <>
      <section className="header">
        <div className="header__left">
          <div className="header__pic">
              <img src={images.scheduling_blue} alt="clients" />
          </div>
          <h1 className="header__title">
            Scheduling
          </h1>
        </div>
        <div className="header__right">
          <button
            disabled={currentUser.type === 'CLIENT' && (!currentUser.formsVerified || !currentUser.paymentTypeVerified)}
            type="button"
            className="header__button flex-button"
            onClick={() => setObjectSessionCreate({date: moment(), client: null})}
            title={(currentUser.type === 'CLIENT' && (!currentUser.formsVerified || !currentUser.paymentTypeVerified))
            ? "You can't book the session untill forms and payment type are verified"
            : "Book a session"}
          >
            <img src={images.add} alt="add session" className="flex-button__img" />
            <p className="flex-button__text">
                Book a session
            </p>
          </button>
        </div>
      </section>
      <section className="calendar">
        <div className="calendar__nav">
          <div className="calendar__date date">
            <button
              type="button"
              className="date__button"
              onClick={() => handlePrev()}
              title="Previous month"
            >
              <img
                src={images.prev}
                alt="previous"
              />
            </button>
            <p className="date__text">
              {toggler === 'month' && moment(date).format('MMMM YYYY')}
              {toggler === 'day' && moment(date).format('MMMM Do YYYY')}
              {toggler === 'week' &&
                moment(date).format('MMMM Do YYYY')
                  + ' - '
                  + moment(date).add(7, 'days').format('MMMM Do YYYY')}
            </p>
            <button
              type="button"
              className="date__button"
              onClick={() => handleNext()}
              title="Next month"
            >
              <img
                src={images.next}
                alt="next"
              />
            </button>
          </div>

          {/* <div className="calendar__view">
            <input
              className="view__item"
              type="radio"
              name="view-toggler"
              id="day"
              onChange={(event) => setToggler(event.target.id)}
              checked={toggler === "day"}
            />
            <label
              htmlFor="day"
              className="view__toggler-label"
            >
              Day
            </label>
            <input
              className="view__item"
              type="radio"
              name="view-toggler"
              id="week"
              onChange={(event) => setToggler(event.target.id)}
              checked={toggler === "week"}
            />
            <label
              htmlFor="week"
              className="view__toggler-label"
            >
              Week
            </label>
            <input
              className="view__item"
              type="radio"
              name="view-toggler"
              id="month"
              onChange={(event) => setToggler(event.target.id)}
              checked={toggler === "month"}
            />
            <label
              htmlFor="month"
              className="view__toggler-label"
            >
              Month
            </label>
          </div> */}
        </div>
        {/* {toggler === "month" && ( */}
          <SchedulingMonth
            date={date}
            setObjectSessionCreate={setObjectSessionCreate}
            setObjectSession={setObjectSession}
            sessions={sessions}
          />
        {/* )} */}
        {/* {toggler === "week" && (
          <SchedulingWeek date={date} setObjectSessionCreate={setObjectSessionCreate} />
        )}
        {toggler === "day" && (
          <SchedulingWeek date={date} setObjectSessionCreate={setObjectSessionCreate} />
        )} */}
      </section>

      {objectSessionCreate && <Modal
        isOpen={objectSessionCreate ? true : false}
        data={objectSessionCreate}
        onClose={(data) => handleUpdate(data)}
        name='session-create'/>}

      {objectSession && <Modal
        isOpen={objectSession ? true : false}
        data={objectSession}
        onClose={(data) => handleUpdate(data)}
        name='session'/>}
        
      {isUserActive && <Modal
        isOpen={isUserActive ? true : false}
        data={isUserActive}
        onClose={() => dispatch(actions.setIsUserActive(null))}
        name='profile'/>}
    </>
  );
};
