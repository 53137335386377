import {getToken} from "./token";

const headersDefault = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': `${getToken()}`,
    }
}

export const BASE_URL = 'https://api.i-practice.online/api';
export const DEV_URL = 'https://api.onelogicsoft.com/ipractice/api';

export const request = (url, options) => {
  return fetch(`${BASE_URL}${url}`, {headers: {...headersDefault(), ...options.headers}, ...options})
    .then(response => {
      if (response.status !== 200) {
          throw new Error(`${response.status}`);
      }
      return response.json();
  })
};

export const mltipartRequest = (url, options) => {
  return fetch(`${BASE_URL}${url}`, {headers: {'Authorization': `${getToken()}`, ...options.headers}, ...options})
    .then(response => {
      if (response.status !== 200) {
          throw new Error(`${response.status}`);
      }
      return response.json();
  })
};
