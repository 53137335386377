import React from 'react';
import './style.css';

export const Page = React.forwardRef(({ children }, ref) => {

  return (
    <div
      className="template" 
      ref={ref}
    >
      {children}
    </div>
  );
}); 
