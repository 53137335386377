import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectors, actions } from '../../../redux/store';
import { TodoItem } from '../Item';
import { Modal } from "../../Modal";
import { Pagination } from '../../Pagination';

export const TodoList = ({loadTodos}) => {
  const todos = useSelector(selectors.getTodos);
  const query = useSelector(selectors.getQuery);
  const isUserActive = useSelector(selectors.getIsUserActive);
  const [objectTodo, setObjectTodo] = useState(null);
  const [sort, setSort] = useState('id');
  const [page, setPage] = useState(1);
  const [pageStart, setPageStart] = useState(0);
  const [pageEnd, setPageEnd] = useState(10);
  const dispatch = useDispatch();

  const sortedTodos = useMemo(() => {
    return ([...todos]
      .filter(todo => ((todo.text + todo.title + todo.deadline).toString().toLowerCase()
              .includes(query.toString().toLowerCase().replace(/ +/g, '')))
      ))
      // eslint-disable-next-line
      .sort((a, b) => {
        if (a[sort]) {
          if (isNaN(a[sort])) {
            return a[sort].localeCompare(b[sort]);
          };

          return a[sort] - b[sort];
        };
      });
  }, [todos, sort, query]);

  const handleUpdate = (data) => {
    if (data) {
      loadTodos(dispatch);
    };

    setObjectTodo(null);
  };

  return (
    <>
      <table className="sessions__table">
        <thead>
            <tr className='sessions__row-first sessions__row' title="Sort">
              <th className='sessions__row-item' onClick={() => setSort('title')}>Title</th>
              <th className='sessions__row-item' onClick={() => setSort('creationDate')}>Created</th>
              <th className='sessions__row-item' onClick={() => setSort('deadline')}>Deadline</th>
              <th className='sessions__row-item' onClick={() => setSort('priority')}>Priority</th>
              <th className='sessions__row-item' onClick={() => setSort('text')}>Text</th>
            </tr>
        </thead>
        <tbody>
            {sortedTodos.slice(pageStart, pageEnd).map(todo => (
              <tr
                key={todo.id}
                className={!todo.isActive ? "sessions__row sessions__row--not-active" : "sessions__row"}
                onClick={() => setObjectTodo(todo)}
                title={!todo.isActive ? "NOT ACTIVE" : "ACTIVE"}
              >
                <TodoItem todo={todo} />
              </tr>
            ))}
          </tbody>
        </table>

        {sortedTodos && (
          <Pagination 
            page={page}
            setPage={setPage}
            itemsQuantity={sortedTodos.length}
            pageStart={pageStart}
            setPageStart={setPageStart}
            pageEnd={pageEnd}
            setPageEnd={setPageEnd}
          />
        )}

        {objectTodo && <Modal
          isOpen={objectTodo ? true : false}
          data={objectTodo}
          onClose={(data) => handleUpdate(data)}
          name='todo'/>}
          
        {isUserActive && <Modal
          isOpen={isUserActive ? true : false}
          data={isUserActive}
          onClose={() => dispatch(actions.setIsUserActive(null))}
          name='profile'/>}
    </>
  );
};
