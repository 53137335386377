import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectors } from '../../../../redux/store';
import { images } from '../../../../helper';
import { getAllTodos, removeTodo } from '../../../../api/todo';
import { Modal } from '../..';
import CircularProgress from "@material-ui/core/CircularProgress";

const moment= require('moment');

export const Todos = ({data}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [objectTodo, setObjectTodo] = useState(null);
  const [todos, setTodos] = useState(null);
  const [objectConfirm, setObjectConfirm] = useState(null);
  const selectedClient = data.client;
  const selectedSession = data.session;
  const currentUser = useSelector(selectors.getCurrentUser);

  const loadTodos = async (id, client, session) => {
    try {
      setIsLoading(true);
      const todos = await(getAllTodos(id));
      setTodos(todos
          .filter(todo => {
            if (client) {
              return (todo.clientId === client.id);
            };

            if (client && session) {
              return ((todo.clientId === client.id) && (todo.sessionId === session.id));
            };

            return (todo.sessionId === session.id);
          }));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    loadTodos(currentUser.id, selectedClient, selectedSession);
  }, [currentUser.id, selectedClient, selectedSession]);

  const handleUpdate = (data) => {
    if (data) {
      loadTodos(currentUser.id, selectedClient, selectedSession);
    };

    setObjectTodo(null);
  };

  const remove = async (todoId) => {
    try {
      await(removeTodo(todoId));
    } catch (error) {
      console.log(error);
    } finally {
      loadTodos(currentUser.id, selectedClient, selectedSession);
    };
  };

  const handleConfirm = (data) => {
    remove(data);
    setObjectConfirm(null);
  };

  const handleDelete = async (event, todoId) => {
    event.preventDefault();
    event.stopPropagation();

    setObjectConfirm({message: 'delete this note', id: todoId});
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress className="progress" />
      ) : (!todos || !todos.length) ? (
        <p className="message">You don't have todos yet</p>
        ) : (
          <table className="notes">
            <tbody>
              {todos.map(todo => (
                <tr
                  className="notes__row"
                  onClick={() => setObjectTodo(todo)}
                  key={todo.id}
                >
                  <td className="notes__row-item">
                    <p>
                      {`${todo.text.slice(0, 15)}...`}
                    </p>
                    <p className="highlighted">
                      {`Created: ${moment(todo.creationDate).format('MM/DD/YYYY') || '-'}`}
                    </p>
                  </td>
                  <td className="notes__row-item">
                    <p className="highlighted">
                      {todo.type}
                    </p>
                  </td>
                  <td className="notes__row-item">
                    <button
                      className="notes__row-button"
                      title="Delete"
                      onClick={(event) => handleDelete(event, todo.id)}
                    >
                      <img src={images.trash_bin} alt="delete" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      )}

      {objectTodo && <Modal
        isOpen={objectTodo ? true : false}
        data={objectTodo}
        onClose={(data) => handleUpdate(data)}
        name='todo'/>}

      {objectConfirm && <Modal
        isOpen={objectConfirm ? true : false}
        data={objectConfirm}
        onClose={(data) => handleConfirm(data)}
        name='confirm'/>}
    </>
  );
};
