import { request } from './api';

export const createPayment = (data) => {
  return request(`/payment/charge`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const confirmPayment = (data) => {
  return request(`/payment/confirm`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
};

export const PUBLIC_KEY = 'pk_test_51IB3NDI15uKwutfRQkqMtm2icEjmXM3nBv3LWAOM5Cv1BmVbbx9ZUSo2XfjJ1G8vIrvBEfz8BJ5AqSZIzgfkNPed00k1iB2yat';
