import React, { useState, useEffect, useMemo } from 'react';
import { useSelector }from 'react-redux';
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';
import './style.css';

import { images, getSlots } from '../../../../helper';
import { createSession } from '../../../../api/sessions';
import { getAllServices, getService } from '../../../../api/services';
import { getScheduleByDate } from '../../../../api/schedule';
import { getAllUsers } from '../../../../api/users';
import { selectors } from '../../../../redux/store';
import { Modal } from '../../../Modal';
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';
import { checkSession } from '../../../../api/sessions';

const moment = require('moment');

export const SessionCreate = ({ data, handleClose }) => {
  const currentUser = useSelector(selectors.getCurrentUser);
  const [selectedDate, setSelectedDate] = useState(data.date || moment());
  const [objectPayment, setObjectPayment] = useState(null);
  const [objectMessage, setObjectMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(data.client || clients[0]);
  const [services, setServices] = useState(null);
  const [session, setSession] = useState({
    providerId: currentUser.id,
    billingStatus: 'UNBILLED',
    client,
  });
  const [schedule, setSchedule] = useState([]);
  const [service, setService] = useState({});
  const [toggler, setToggler] = useState("service");

  const filteredServiceNames = useMemo(() => {
    if (services) {
      return [...new Set(services.map(item => item.serviceName))]
    }
  }, [services]);

  const filteredServiceTypes = useMemo(() => {
    if (service && services) {
      return (services.filter(s => s.serviceName === service.serviceName).map(item => item.serviceType))
    }
  }, [service, services]);

  const loadSchedule = async (id, date) => {
    try {
      const schedule = await(getScheduleByDate(id, moment(date).format('MM/DD/YYYY')));
      if(!schedule.length) return setSchedule([]);

      schedule.forEach(item => setSchedule(item.intervals));
    } catch (error) {
      console.log(error);
    };
  };

  const loadServices = async () => {
    try {
      const services = await(getAllServices());
      setServices(services);
    } catch (error) {
      console.log(error);
    };
  };

  const loadClients = async () => {
    try {
      const clients = await(getAllUsers());
      setClients(clients);
    } catch (error) {
        console.log(error);
    };
  };

  useEffect(() => {
    loadServices();
    if (moment(selectedDate).isAfter(moment())) {
      loadSchedule(currentUser.id, selectedDate);
    } else {
      setSchedule([]);
    };
    if (currentUser.type === 'PROVIDER') {
      loadClients();
    } else {
      setClient(currentUser);
      setSession({
        ...session,
        client: currentUser,
      });
    }
  //eslint-disable-next-line
  }, [currentUser.id, selectedDate, currentUser.type]);

  const handleSlot = (event) => {
    setSession({
      ...session,
      sessionDate: moment(event.target.value).utc(true),
    });
  };

  const handleService = async (event, serv) => {
    const selectedService = services
      .find(item => (item.serviceName === service.serviceName)
        && (item.serviceType === serv));
    try {
      const service = await(getService(selectedService.id));
      setService(service);
      setSession({
        ...session,
        sessionType: service.serviceName,
        serviceId: service.id,
        duration: service.duration,
      });
    } catch (error) {
      console.log(error);
    };
  };

  const handleServiceName = async (event) => {
      setService({
        ...service,
        [event.target.name]: event.target.value,
      });

      if ((event.target.value === 'Clinical supervision') || (event.target.value === 'Initial Evaluation')) {
        const selectedService = services
          .find(item => (item.serviceName === event.target.value));
        try {
          const service = await(getService(selectedService.id));
          setService(service);
          setSession({
            ...session,
            sessionType: service.serviceName,
            serviceId: service.id,
            duration: service.duration,
          });
        } catch (error) {
          console.log(error);
        };
    }
  };

  const onChange = (date) => {
    if (moment(date).isAfter(moment())) {
      setSelectedDate(date);
      loadSchedule(currentUser.id, date);
    } else {
      setSchedule([]);
    };
  };

  const handleClient = (newClient) => {
    setClient(newClient);
    setSession({
      ...session,
      client: newClient,
    });
    console.log(newClient);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await checkSession(session);
      await(createSession(session));
      setIsLoading(false);
      setObjectMessage('Session was saved successfuly!')
      console.log(session);
    } catch (error) {
      console.log(error);
      setError(error);
    };
    setIsLoading(false);
  };

  const handlePay = async () => {
    try {
      setIsLoading(true);
      await checkSession(session);
      if (+session.client.coPay > 0) {
        setObjectPayment({
          amount: +session.client.coPay,
          session,
        });
      } else {
        setObjectPayment({
          amount: service.price,
          session,
        });
      }
    } catch (error) {
      console.log(error);
      setError(error);
    };
    setIsLoading(false);
  };

  const slots = useMemo(() => (
    [...schedule].map(item => {
      return getSlots(
        moment(item.start),
        moment(item.end),
        service.duration,
        service.bufferTimeAfter,
      );
    })
  ), [service, schedule]);

  return (
    <>
      {error ? (
        <p className="message">Something went wrong! Try again please</p>
      ) : (
        <div className="book__content">
          <div className="book__block">
            <input
              type="checkbox"
              id="service"
              className="book__tab-toggler"
              onChange={() => setToggler('service')}
              checked={toggler === "service"}
            />
            <label
              htmlFor="service"
              className={(session.sessionType && toggler !== "service") ? "book__tab-toggler-label--visited book__tab-toggler-label" : "book__tab-toggler-label"}
            >
              <span className="book__side-circle">1</span>
              <p>Please select service</p>
              {(session.sessionType && toggler !== "service") && (
                <button
                  onClick={() => setToggler("service")}
                  type="button"
                  className="modal__icon-button"
                >
                  <img
                    src={images.edit}
                    alt="edit"
                    className="book__tab-edit"
                  />
                </button>
              )}
            </label>
            <div className="book__block-content">
              <ul className="book__toggler-group">
                {filteredServiceNames && filteredServiceNames.map(item => (
                  <li key={item} className="book__toggler-item">
                    <input
                      type="checkbox"
                      id={item}
                      className="modal__toggler"
                      name="serviceName"
                      value={item}
                      onChange={(event) => handleServiceName(event, item)}
                      checked={service.serviceName === item}
                    />
                    <label htmlFor={item} className="modal__toggler-label">
                      {item}
                    </label>
                  </li>
                ))}
              </ul>
                {(service && service.serviceName && filteredServiceTypes[0]) && (
                  <>
                    <h3 className="modal__secondary-title">
                      Service type
                    </h3>
                    <ul className="book__toggler-group">
                      {filteredServiceTypes.map(item => (
                        <li key={item} className="book__toggler-item">
                          <input
                            type="checkbox"
                            id={item}
                            className="modal__toggler"
                            name="serviceType"
                            value={item}
                            onChange={(event) => handleService(event, item)}
                            checked={service.serviceType === item}
                          />
                          <label htmlFor={item} className="modal__toggler-label">
                            {item}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              <button
                type="button"
                onClick={() => setToggler("date")}
                className="modal__button"
                disabled={!session.sessionType}
              >
                Next
              </button>
            </div>
          </div>
          <div className="book__block book__block-sideline">
            <input
              type="checkbox"
              id="date"
              className="book__tab-toggler"
              onChange={() => setToggler('date')}
              checked={toggler === "date"}
            />
            <label
              htmlFor="date"
              className={(session.sessionDate && toggler !== "date") ? "book__tab-toggler-label--visited book__tab-toggler-label" : "book__tab-toggler-label"}
            >
              <span className="book__side-circle">2</span>
              <p>{`Pick Date & Time`}</p>
              {(session.sessionDate && toggler !== "date") && (
                <button
                  onClick={() => setToggler("date")}
                  type="button"
                  className="modal__icon-button"
                  >
                  <img
                    src={images.edit}
                    alt="edit"
                    className="book__tab-edit"
                  />
                </button>
              )}
            </label>
            <div className="book__block-content">
              <div className="modal__field book__field">
                <h3 className="modal__secondary-title">
                  Date
                </h3>
                <Calendar
                  className="date-picker"
                  onChange={(date) => onChange(date)}
                  tileClassName="calendar-item"
                  prev2Label={null}
                  next2Label={null}
                  locale="en"
                  value={selectedDate._d}
                />
              </div>
              <div className="modal__field book__field">
                {slots.length === 0 ? (
                  <p className="message">No avaliable time for chosen date. Pick another date or service type, please.</p>
                ) : (
                  <>
                    <h3 className="modal__secondary-title">
                      Time
                    </h3>
                    <ul className="book__toggler-group">
                      {slots.map(item => item.map(interval => (
                        <li key={Math.random()} className="book__toggler-item">
                          <input
                            id={interval.begin}
                            type="checkbox"
                            className="modal__toggler"
                            onChange={handleSlot}
                            checked={session.sessionDate && (session.sessionDate._i === interval.begin)}
                            value={interval.begin}
                          />
                          <label htmlFor={interval.begin} className="modal__toggler-label">
                            {`${moment(interval.begin).format('h:mm a')} - ${moment(interval.finish).format('h:mm a')}`}
                          </label>
                        </li>
                      )))}
                    </ul>
                  </>
                )}
                {currentUser.type === 'PROVIDER' && (
                  <button
                    type="button"
                    onClick={() => {
                      setToggler("client");
                      window.scrollTo(0, 0);
                    }}
                    className="modal__button"
                    disabled={!session.sessionDate}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>

          {currentUser.type === 'PROVIDER' && (
            <div className="book__block">
              <input
                type="checkbox"
                id="client"
                className="book__tab-toggler"
                onChange={() => setToggler('client')}
                checked={toggler === "client"}
              />
              <label
                htmlFor="client"
                className={(session.client && toggler !== "client") ? "book__tab-toggler-label--visited book__tab-toggler-label" : "book__tab-toggler-label"}
              >
                <span className="book__side-circle">3</span>
                <p>Client</p>
                {(session.client && toggler !== "client") && (
                  <button
                    onClick={() => setToggler("client")}
                    type="button"
                    className="modal__icon-button"
                  >
                    <img
                      src={images.edit}
                      alt="edit"
                      className="book__tab-edit"
                    />
                  </button>
                )}
                </label>
              <div className="book__block-content">
                <Autocomplete
                  id="client-choose"
                  className="modal__autocomplete"
                  getOptionSelected={(option, value) => option.firstName === value.firstName}
                  options={clients}
                  getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
                  style={{ width: '100%' }}
                  value={client}
                  onChange={(event, newClient) => handleClient(newClient)}
                  renderInput={(params) => <TextField {...params} label="Choose client" variant="outlined" />}
                />
                {/* <button
                  type="button"
                  onClick={() => setToggler("payment")}
                  className="modal__button"
                  disabled={!session.client}
                >
                  Next
                </button> */}
              </div>
            </div>
          )}
          
          {/* <div className="book__block">
            <input
              type="checkbox"
              id="payment"
              className="book__tab-toggler"
              onChange={() => setToggler('payment')}
              checked={toggler === "payment"}
            />
            <label
              htmlFor="payment"
              className={(session.payment && toggler !== "payment") ? "book__tab-toggler-label--visited book__tab-toggler-label" : "book__tab-toggler-label"}
            >
              <span className="book__side-circle">4</span>
              <p>Payment</p>
              {(session.payment && toggler !== "payment") && (
                <button
                  onClick={() => setToggler("payment")}
                  type="button"
                  className="modal__icon-button"
                >
                  <img
                    src={images.edit}
                    alt="edit"
                    className="book__tab-edit"
                  />
                </button>
              )}
              </label>
            <div className="book__block-content">
              <h3 className="modal__secondary-title">
                Price
                <span className="highlighted">{` ${service.price}$`}</span>
              </h3>
              <button
                type="button"
                className="modal__button"
                onClick={() => setObjectPayment({amount: service.price})}
              >
                {isLoading ? (
                  <CircularProgress className="modal__button-progress" />
                ) : "Pay"}
              </button>
            </div>
          </div> */}
        </div>
      )}

      <div className="modal__buttons">
        <button
          type="button"
          className="modal__button book__submit-button"
          onClick={
            error
              ? (event) => handleClose(event, null)
              : ((session.client && currentUser.type === 'CLIENT') && ((session.client.paymentTypeVerified === 'SELFPAY') || (session.client.paymentTypeVerified === 'UNSET') || (session.client.paymentTypeVerified === 'COPAY'))
                ? () => handlePay()
                : () => handleSubmit())
            }
          disabled={!(
            session.sessionDate
              && session.sessionType
              && session.client
          )}
        >
          {isLoading ? (
            <CircularProgress className="modal__button-progress" />
          ) : (error ? "Back" : "Submit")}
        </button>
      </div>

      {objectPayment && <Modal
        isOpen={objectPayment ? true : false}
        data={objectPayment}
        onClose={(data) => handleClose(null, data)}
        name='payment'/>}

      {objectMessage && <Modal
        isOpen={objectMessage ? true : false}
        data={objectMessage}
        onClose={(data) => handleClose(null, data)}
        name='message'/>}
    </>
  );
};
