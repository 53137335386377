import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom"

import { changePassword } from '../../../api/auth';
import CircularProgress from "@material-ui/core/CircularProgress";
import { setToken, getToken } from "../../../api/token";
import { errorClass } from '../../../helper';

export const CreatePassword = ({ match, location }) => {
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [loading, setLoading] = useState(false);
  const [isPasswordRepeatValid, setIsPasswordRepeatValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState({passwordRepeat: '', password: ''});

  useEffect(() => {
    if (match.params.token) {
      setToken({
        tokenType: 'Bearer',
        accessToken: match.params.token,
      });
    } else {
      setToken(null);
      return <Redirect to="/signin" />
    };
  }, [location, match]);


  const handlePassword = (event) => {
    setPassword(event.target.value);
    validateField('password', event.target.value);
  };

  const handlePasswordRepeat = (event) => {
    setPasswordRepeat(event.target.value);
    validateField('passwordRepeat', event.target.value);
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let passwordRepeatValid = isPasswordRepeatValid;
    let passwordValid = isPasswordValid;
    switch(fieldName) {
      case 'passwordRepeat':
        passwordRepeatValid = (value === password);
        fieldValidationErrors.passwordRepeat = passwordRepeatValid ? '' : 'Password does not match';
        break;
      case 'password':
        passwordValid = value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/);
        fieldValidationErrors.password = passwordValid ? '': 'Password is too short or does not contain special characters and numbers';
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setIsPasswordRepeatValid(passwordRepeatValid);
    setIsPasswordValid(passwordValid);
    if (passwordValid && passwordRepeatValid) {
      setIsFormValid(true);
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true);
    try {
      const token = getToken();
      await(changePassword(password, token));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  return (
      <section className="auth__popup">
        {loading ? (
          <CircularProgress />
        ) : (
          <form
            className="auth__form"
            onSubmit={handleSubmit}
          >
            <Link to="/client-registration" className="auth__link auth__link--light restore-password__link">
              {`< Back to Attributes`}
            </Link>
            <h1 className="auth__title">
              Create Password
            </h1>
            <div className="auth__field">
              <label
                htmlFor="password-1"
                className="auth__label"
              >
                Password
              </label>
              <input
                type="password"
                id="password-1"
                onChange={handlePassword}
                value={password}
                name="password"
                placeholder="Enter password"
                className={`auth__input ${errorClass(formErrors.password)}`}
              />
              <p class="error-message">
                {formErrors.password}
              </p>
            </div>
            <div className="auth__field">
              <label
                htmlFor="password-2"
                className="auth__label"
              >
                Password
              </label>
              <input
                type="password"
                id="password-2"
                onChange={handlePasswordRepeat}
                value={passwordRepeat}
                name="passwordRepeat"
                placeholder="Repeat password"
                className={`auth__input ${errorClass(formErrors.passwordRepeat)}`}
              />
              <p class="error-message">
                {formErrors.passwordRepeat}
              </p>
            </div>
            <button
              type="submit"
              className="auth__button"
              disabled={!isFormValid}
            >
              {`Create account & save password`}
            </button>
          </form>
          )}
      </section>
  );
};
