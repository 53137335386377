import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from "@material-ui/core/CircularProgress";
import { images } from '../../helper';
// import { selectors, actions } from '../../redux/store';
import { InvoiceList } from './List';
// import { Modal } from '../Modal';

export const Invoice = () => {
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line
    const [error, setError] = useState(null);
    // eslint-disable-next-line
    const [invoices, setInvoices] = useState(null);

    return (
        <>
            <section className="header">
                <div className="header__left">
                    <div className="header__pic">
                        <img src={images.invoice} alt="invoice" />
                    </div>
                    <h1 className="header__title">
                        Invoice
                    </h1>

                </div>
                <div className="header__right">
                    <a
                        href="assets/forms/form-cms.pdf"
                        download
                        className="header__button flex-button"
                        title="Upload form"
                    >
                        <img
                            src={images.upload}
                            alt="upload"
                            className="flex-button__img"
                        />
                        <p className="flex-button__text">
                            {`Download blank`}
                        </p>
                    </a>
                </div>
            </section>

            <section className="clients__nav">
                <input
                    className="header__search"
                    type="text"
                    placeholder="Search"
                    // value={query}
                    // onChange={handleChanges}
                />
            </section>
            {isLoading ? (
                <CircularProgress className="progress" />
            ) :
                error ? (
                    <p className="clients__message">Not Found</p>
                ) :
                    invoices ? (
                        <InvoiceList/>
                    ) : (
                        <p className="clients__message">You don't have invoice yet</p>
            )}
        </>
    );
};
