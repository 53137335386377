import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectors } from '../../../../redux/store';
import CircularProgress from "@material-ui/core/CircularProgress";
import { setToken } from "../../../../api/token";
import { logout } from '../../../../api/auth';
import { Modal } from '../../../Modal';

const moment= require('moment');

export const Profile = ({ data, handleClose }) => {
  const currentUser = useSelector(selectors.getCurrentUser);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [objectSettings, setObjectSettings] = useState(null);

  const handleLogout = async () => {
    try {
      await(logout());
    } catch (error) {
      console.log(error);
    } finally {
      setToken(null);
      localStorage.clear();
      window.location.reload();
    };
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress className="progress" />
      ) : (
        <>
          <div className="modal__list">
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Name
              </h3>
              <p className="client__info-text modal__text">{currentUser.firstName}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Lastname
              </h3>
              <p className="client__info-text modal__text">{currentUser.lastName}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Phone
              </h3>
              <p className="client__info-text modal__text">{currentUser.phoneNumber}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Email
              </h3>
              <p className="client__info-text modal__text">{currentUser.email}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Address
              </h3>
              <p className="client__info-text modal__text">{currentUser.address}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Birth date
              </h3>
              <p className="client__info-text modal__text">{moment(currentUser.birthDate).format('MM/DD/YYYY')}</p>
            </div>
            <div className="client__info-item">
              <h3 className="client__info-title modal__secondary-title">
                Gender
              </h3>
              <p className="client__info-text modal__text">{currentUser.gender}</p>
            </div>
          </div>

          <div className="modal__buttons">
            <button
              type="button"
              onClick={handleLogout}
              className="modal__button"
            >
              Logout
            </button>
            <button
              type="button"
              className="modal__button"
              onClick={() => setObjectSettings(currentUser)}
            >
              Settings
            </button>
          </div>
        </>
      )}

      {objectSettings && <Modal
        isOpen={objectSettings ? true : false}
        data={objectSettings}
        onClose={() => setObjectSettings(null)}
        name={currentUser.type === 'PROVIDER' ? 'profile-settings-provider' : 'profile-settings-client'}/>}
    </>
  );
};
