import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

export const FormSignature = ({ handleClose }) => {
  let signature = useRef(null);
  const [message, setMessage] = useState(null);

  const handleSave = () => {
    if (!(signature.current.isEmpty())) {
      handleClose(null, signature.current.toDataURL());
    } else {
      setMessage('Put a signature please')
    };
  };

  return (
   <>
      <SignatureCanvas
        ref={signature}
        canvasProps={{width: 600, height: 400, className: 'sigCanvas'}}
      />
      <div className="modal__buttons">
        <button
          type="button"
          onClick={() => handleSave()}
          className="modal__button"
        >
          Save
        </button>
        {message && (
          <p className="message">
            {message}
          </p>
        )}
      </div>
    </>
  );
};