import React, { useState, useEffect } from 'react';

import 'react-calendar/dist/Calendar.css';

import { images, stringNormalise } from '../../../../helper';
import { getSession, deleteSession } from '../../../../api/sessions';
import { Modal } from '../../../Modal';
import CircularProgress from "@material-ui/core/CircularProgress";

const moment= require('moment');

export const Session = ({ data, handleClose }) => {
  const [selectedSession, setSelectedSession] = useState(data);
  const [objectSessionEdit, setObjectSessionEdit] = useState(null);
  const [objectConfirm, setObjectConfirm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadSession(selectedSession.id);
  }, [selectedSession.id]);

  const loadSession = async (id) => {
    try {
      setIsLoading(true);
      const data = await(getSession(id));
      setSelectedSession(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    };
  };

  const removeClient = async (id) => {
    setIsLoading(true);

    try {
      await(deleteSession(id));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      handleClose(null, id);
    };
  };

  const handleUpdate = (data) => {
    if (data) {
      loadSession(selectedSession.id);
    };

    setObjectSessionEdit(null);
  };

  const handleConfirm = (data) => {
    if (data) {
      removeClient(selectedSession.id);
    };

    setObjectConfirm(null);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress className="progress" />
      ) : (
        <>
          <div className="modal__content-block">
            <h3 className="modal__secondary-title">
              Service
            </h3>
            <p className="modal__text highlighted">
              {selectedSession.sessionType}
            </p>
          </div>
          <div className="modal__content-block">
            <h3 className="modal__secondary-title">
              Date and Time
            </h3>
            <p className="modal__label">
              Date: 
              <span className="modal__text highlighted">
                {` ${moment(selectedSession.sessionDate).format('MM/DD/YYYY')}`}
              </span>
            </p>
            <p className="modal__label">
              Time: 
              <span className="modal__text highlighted">
                {` ${moment(selectedSession.sessionDate).format('h:mm')}`}
              </span>
            </p>
          </div>
          <div className="modal__content-block">
            <h3 className="modal__secondary-title">
              Payment info
            </h3>
            <p className="modal__label">
              Billing Status: 
              <span className="modal__text highlighted">
                {` ${stringNormalise(selectedSession.billingStatus)}`}
              </span>
            </p>
            <p className="modal__label">
              Co Pay: 
              <span className="modal__text highlighted">
                {selectedSession.client.coPay ? ` ${selectedSession.client.coPay}` : ' Unset'}
              </span>
            </p>
            <p className="modal__label">
              Payment Type: 
              <span className="modal__text highlighted">
                {selectedSession.client.paymentTypeVerified !== null ? ` ${stringNormalise(selectedSession.client.paymentTypeVerified)}` : ' Unset'}
              </span>
            </p>
          </div>
          <div className="modal__content-block">
            <h3 className="modal__secondary-title">
              Client
            </h3>
            <p className="modal__label">
              Name: 
              <span className="modal__text highlighted">
                {` ${selectedSession.client.firstName} ${selectedSession.client.lastName}`}
              </span>
            </p>
            <p className="modal__label">
              Email: 
              <span className="modal__text highlighted">
              {`  ${selectedSession.client.email}`}
              </span>
            </p>
            <p className="modal__label">
              Phone: 
              <span className="modal__text highlighted">
                {`  ${selectedSession.client.phoneNumber}`}
              </span>
            </p>
          </div>
          <div className="modal__icon-buttons">
            <button
              className="modal__icon-button"
              type="button"
              onClick={() => setObjectConfirm({message: `delete ${selectedSession.client.firstName} ${selectedSession.client.lastName}'s session`})}
              >
              <img
                src={images.trash_bin}
                alt="delete"
              />
            </button>
            <button
              className="modal__icon-button"
              type="button"
              onClick={() => setObjectSessionEdit(selectedSession)}
            >
              <img
                src={images.edit}
                alt="edit"
              />
            </button>
          </div>
        </>
      )}

      {objectSessionEdit && <Modal
        isOpen={objectSessionEdit ? true : false}
        data={objectSessionEdit}
        onClose={(data) => handleUpdate(data)}
        name='session-edit'/>}

      {objectConfirm && <Modal
        isOpen={objectConfirm ? true : false}
        data={objectConfirm}
        onClose={(data) => handleConfirm(data)}
        name='confirm'/>}
    </>
  );
};