import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import './style.css';

import { images } from '../../../helper';
import { selectors, actions } from '../../../redux/store';
import { Modal } from '../../Modal';

export const Dashboard = () => {
  const currentUser = useSelector(selectors.getCurrentUser);
  const isUserActive = useSelector(selectors.getIsUserActive);
  const dispatch = useDispatch();

  return (
    <div className="dashboard">
      <header className="dashboard__header">
        <a href="/" className="dashboard__logo">
          <img
            src={images.logo}
            alt="logo"
            className="dashboard__logo-img"
          />
          <h1 className="dashboard__logo-title">
            iPractice
          </h1>
        </a>
        <div
          className="dashboard__user"
          onClick={() => {
            dispatch(actions.setIsUserActive(currentUser));
          }}>
          <div className="dashboard__user-info">
            <p className="dashboard__user-name">
              {`${currentUser.firstName} ${currentUser.lastName}`}
            </p>
            <p className="dashboard__user-position">
              {currentUser.type}
            </p>
          </div>
          <img
            src={images.doctor}
            alt="provider"
            className="dashboard__user-img"
          />
          <button
            type="button"
            onClick={() => {
              dispatch(actions.setIsUserActive(currentUser));
            }}
            className="dashboard__user-options"
          >
            <img src={images.options} alt="options"/>
          </button>
        </div>
      </header>
      <main className="dashboard__content">
        <div className="cards">
          {currentUser.type === 'PROVIDER' ? (
            <Link to="/clients" className="card">
              <img
                src={images.clients_blue}
                alt="clients"
                className="card__img"
              />
              <div className="card__content">
                <h2 className="card__title">
                  Clients
                </h2>
                <p className="card__text">
                  Secure electronic document storage system.
                </p>
              </div>
            </Link>
          ) : (
            <Link to="/providers" className="card">
              <img
                src={images.clients_blue}
                alt="providers"
                className="card__img"
              />
              <div className="card__content">
                <h2 className="card__title">
                  Providers
                </h2>
                <p className="card__text">
                  Secure electronic document storage system.
                </p>
              </div>
            </Link>
          )}
          <Link to="/sessions" className="card">
            <img
              src={images.sessions_blue}
              alt="sessions"
              className="card__img"
            />
            <div className="card__content">
              <h2 className="card__title">
                Sessions
              </h2>
              <p className="card__text">
                Secure client/provider communication system.
              </p>
            </div>
          </Link>
          <Link to="/scheduling" className="card">
            <img
              src={images.scheduling_blue}
              alt="scheduling"
              className="card__img"
            />
            <div className="card__content">
              <h2 className="card__title">
                Scheduling
              </h2>
              <p className="card__text">
                Secure client/provider communication system.
              </p>
            </div>
          </Link>
          <Link to="/todo" className="card">
            <img
              src={images.todo_blue}
              alt="todo"
              className="card__img"
            />
            <div className="card__content">
              <h2 className="card__title">
                To Do
              </h2>
              <p className="card__text">
                Electronic TODO sections.
              </p>
            </div>
          </Link>
          <Link to="/forms" className="card">
            <img
              src={images.forms_blue}
              alt="forms"
              className="card__img"
            />
            <div className="card__content">
              <h2 className="card__title">
                Forms
              </h2>
              <p className="card__text">
                Secure electronic document storage system.
              </p>
            </div>
          </Link>
          <Link to="/invoice" className="card">
            <img
              src={images.invoice}
              alt="invoice"
              className="card__img"
            />
            <div className="card__content">
              <h2 className="card__title">
                Invoice
              </h2>
              <p className="card__text">
                Secure electronic document storage system.
              </p>
            </div>
          </Link>
          {currentUser.type === 'PROVIDER' && (
            <Link to="/" className="card">
              <img
                src={images.analitycs_blue}
                alt="report"
                className="card__img"
              />
              <div className="card__content">
                <h2 className="card__title">
                  Analitycs
                </h2>
                <p className="card__text">
                  Secure electronic document storage system.
                </p>
              </div>
            </Link>
          )}
        </div>
      </main>

      {isUserActive && <Modal
        isOpen={isUserActive ? true : false}
        data={isUserActive}
        onClose={() => dispatch(actions.setIsUserActive(null))}
        name='profile'/>}
    </div>
  );
};
