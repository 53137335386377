import { request } from './api';

export const getAllTodos = () => {
  return request(`/todo/find-all`, {});
};

export const createTodo = (todo) => {
  return request(`/todo`, {
    method: 'POST',
    body: JSON.stringify(todo),
  });
};

export const updateTodo = (todo) => {
  return request(`/todo`, {
    method: 'PUT',
    body: JSON.stringify(todo),
  });
};

export const getTodo = (id) => {
  return request(`/todo/${id}`, {});
};

export const removeTodo = (id) => {
  return request(`/todo/${id}`, {
    method: 'DELETE',
  });
};
