import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectors, actions } from '../../../redux/store';
import { InvoiceItem } from '../Item';
import { Modal } from "../../Modal";
import { Pagination } from '../../Pagination';

export const InvoiceList = ({loadData}) => {
  const forms = useSelector(selectors.getForms);
  const query = useSelector(selectors.getQuery);
  const isUserActive = useSelector(selectors.getIsUserActive);
  const [objectForm, setObjectForm] = useState(null);
  const [objectFormTemplate, setObjectFormTemplate] = useState(null);
  const [sort, setSort] = useState('id');
  const [page, setPage] = useState(1);
  const [pageStart, setPageStart] = useState(0);
  const [pageEnd, setPageEnd] = useState(10);
  const dispatch = useDispatch();

  const sortedForms = useMemo(() => {
    return ([...forms]
      .filter(form => ((form.clientFullName + form.templateName + form.creationDate).toString().toLowerCase()
        .includes(query.toString().toLowerCase().replace(/ +/g, '')))))
        // eslint-disable-next-line
      .sort((a, b) => {
        if (a[sort]) {
          if (isNaN(a[sort])) {
            return a[sort].localeCompare(b[sort]);
          };

          return b[sort] - a[sort];
        };
      });
  }, [forms, sort, query]);

  const handleUpdate = (data) => {
    if (data) {
      loadData(dispatch);
    };

    setObjectForm(null);
    setObjectFormTemplate(null);
  };

  return (
    <>
      <table className="clients__table">
        <thead>
            <tr className="clients__row-first clients__row">
              <th className='clients__row-item' onClick={() => setSort('templateName')}>Template</th>
              <th className='clients__row-item' onClick={() => setSort('clientFullName')}>Client</th>
              <th className='clients__row-item' onClick={() => setSort('id')}>Created</th>
            </tr>
        </thead>
        <tbody>
          {sortedForms.slice(pageStart, pageEnd).map(form => (
            <tr
              className={form.signed ? "clients__row" : "clients__row clients__row--not-active"}
              key={form.id}
              onClick={form.signed ? (() => setObjectForm(form)) : (() => setObjectFormTemplate(form))}
              title={form.signed ? 'PDF form' : 'Draft form'}
            >
              <InvoiceItem form={form} />
            </tr>
          ))}
        </tbody>
      </table>

      {sortedForms && (
        <Pagination 
          page={page}
          setPage={setPage}
          itemsQuantity={sortedForms.length}
          pageStart={pageStart}
          setPageStart={setPageStart}
          pageEnd={pageEnd}
          setPageEnd={setPageEnd}
        />
      )}

      {objectForm && <Modal
        isOpen={objectForm ? true : false}
        data={objectForm}
        onClose={(data) => handleUpdate(data)}
        name='form'/>}

      {objectFormTemplate && <Modal
        isOpen={objectFormTemplate ? true : false}
        data={objectFormTemplate}
        onClose={(data) => handleUpdate(data)}
        name='form-template'/>}
        
      {isUserActive && <Modal
        isOpen={isUserActive ? true : false}
        data={isUserActive}
        onClose={() => dispatch(actions.setIsUserActive(null))}
        name='profile'/>}
    </>
  )
}
