import React, { useState } from 'react';

import './style.css';

import { updateNote } from '../../../../api/notes';
import CircularProgress from "@material-ui/core/CircularProgress";

export const NoteEdit = ({data, handleClose}) => {
  const [selectedNote, setSelectedNote] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  const handleChanges = (event) => {
    setSelectedNote({
      ...selectedNote,
      text: event.target.value,
    });
  };

  const handleType = (event) => {
    setSelectedNote({
      ...selectedNote,
      type: event.target.value,
    });
    console.log(selectedNote);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setIsLoading(true);
      await(updateNote(selectedNote));
      setIsLoading(false);
      handleClose(null, selectedNote.id);
    } catch (error) {
      console.log(error)
    };
  };

  const types = ['Assessment', 'Group', 'Individual', 'Treatment Plan', 'Informational'];

  return (
    <>
      <div className="note__content">
        <textarea
          className="note__textarea"
          onChange={handleChanges}
          value={selectedNote.text}
        ></textarea>

        <div className="modal__buttons">
          <button
            type="botton"
            className="modal__button"
            onClick={(event) => handleSubmit(event)}
          >
            {isLoading ? (
              <CircularProgress className="modal__button-progress" />
            ) : "Save"}
          </button>
          <select
            className="modal__select"
            value={selectedNote.type}
            onChange={handleType}
            title="Choose note group"
          >
            {(selectedNote.sessionId ? types.slice(0, 3) : types).map(type => (
              <option
                key={type}
                value={type}
              >
                {type}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};
