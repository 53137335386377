import { Note } from "./Note";
import { NoteCreate } from "./NoteCreate";
import { NoteEdit } from "./NoteEdit";
import { Notes } from "./Notes";
import { Session } from "./Session";
import { Client } from "./Client";
import { SessionEdit } from "./SessionEdit";
import { ClientEdit } from "./ClientEdit";
import { ClientCreate } from "./ClientCreate";
import { SessionCreate } from "./SessionCreate";
import { Todo } from "./Todo";
import { Todos } from "./Todos";
import { TodoCreate } from "./TodoCreate";
import { TodoEdit } from "./TodoEdit";
import { Form } from "./Form";
import { FormCreate } from "./FormCreate";
import { FormCreateTemplate } from "./FormCreateTemplate";
import { FormSignature } from "./FormSignature";
import { FormUpload } from "./FormUpload";
import { Payment } from "./Payment";
import { Confirm } from "./Confirm";
import { Message } from "./Message";
import { Profile } from "./Profile";
import { ProfileSettingsClient } from "./ProfileSettingsClient";
import { ProfileSettingsProvider } from "./ProfileSettingsProvider";

export default [
    {name: 'note', title:"Note", component: Note},
    {name: 'note-create', title:"Create new note", component: NoteCreate},
    {name: 'note-edit', title:"Edit", component: NoteEdit},
    {name: 'notes', title:"Notes", component: Notes},
    
    {name: 'client', title:"Client", component: Client},
    {name: 'client-edit', title:"Edit", component: ClientEdit},
    {name: 'client-create', title:"Add new client", component: ClientCreate},

    {name: 'todo', title:"Todo", component: Todo},
    {name: 'todo-edit', title:"Edit", component: TodoEdit},
    {name: 'todo-create', title:"Create todo", component: TodoCreate},
    {name: 'todos', title:"Todo list", component: Todos},

    {name: 'form', title:"Form", component: Form},
    {name: 'form-create', title:"Fiil out new form", component: FormCreate},
    {name: 'form-signature', title:"Signature", component: FormSignature},
    {name: 'form-template', title:"Create form", component: FormCreateTemplate},
    {name: 'form-upload', title:"Upload form", component: FormUpload},

    {name: 'session', title:"Session attributes", component: Session},
    {name: 'session-edit', title:"Edit", component: SessionEdit},
    {name: 'session-create', title:"Book an appointment", component: SessionCreate},

    {name: 'payment', title:"Payment", component: Payment},
    {name: 'confirm', title:"Confirm", component: Confirm},
    {name: 'message', title:"Message", component: Message},
    {name: 'profile', title:"My profile", component: Profile},
    {name: 'profile-settings-provider', title:"Settings", component: ProfileSettingsProvider},
    {name: 'profile-settings-client', title:"Settings", component: ProfileSettingsClient},
];
