import React, { useState } from 'react';

import './style.css';

import { images, stringNormalise } from '../../../../helper';
import { getNote, removeNote } from '../../../../api/notes';
import { Modal } from '../..';
import CircularProgress from "@material-ui/core/CircularProgress";

const moment= require('moment');

export const Note = ({data, handleClose}) => {
  const [objectNoteEdit, setObjectNoteEdit] = useState(null);
  const [selectedNote, setSelectedNote] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [objectConfirm, setObjectConfirm] = useState(null);

  const loadNote = async (note) => {
    setIsLoading(true);
    try {
      const noteData = await(getNote(note));
      setSelectedNote(noteData);
    } catch (error) {
      console.log(error);
      handleClose(null);
    };
    setIsLoading(false);
  };

  const handleEdit = () => {
    setObjectNoteEdit(selectedNote);
  };

  const remove = async (noteId) => {
    try {
      setIsLoading(true);
      await(removeNote(noteId));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      handleClose(null, noteId);
    };
  };

  const handleUpdate = (data) => {
    if (typeof data === 'number') {
      loadNote(data);
    };

    setObjectNoteEdit(null);
  };

  const handleConfirm = (data) => {
    if (data) {
      remove(selectedNote.id);
    };

    setObjectConfirm(null);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress className="progress" />
      ) : (
        <>
          <p className="note__text">
            {selectedNote.text}
          </p>
          <div className="note__info">
            <p className="note__info-item">
              {`Type: ${stringNormalise(selectedNote.type)}`}
            </p>
            <p className="note__info-item">
              {`Created: ${moment(selectedNote.creationDate).format('MM/DD/YYYY') || '-'}`}
            </p>
            <p className="note__info-item">
              {`Updated: ${moment(selectedNote.updateDate).format('MM/DD/YYYY') || '-'}`}
            </p>
          </div>
        </>
      )}
      <div className="modal__buttons">
        <button
          type="button"
          className="modal__button"
          onClick={(event) => handleClose(event, selectedNote)}
        >
          Back
        </button>
        <div>
          <button
            type="button"
            onClick={() => handleEdit()}
            title="Edit"
          >
            <img
              src={images.edit}
              alt="edit"
              className="modal__icon-button"
            />
          </button>
          <button
            type="button"
            onClick={() => setObjectConfirm({message: `delete this note`})}
            title="Delete"
          >
            <img
              src={images.trash_bin}
              alt="delete"
              className="modal__icon-button"
            />
          </button>
        </div>
      </div>

      {objectNoteEdit && <Modal
        isOpen={objectNoteEdit ? true : false}
        data={objectNoteEdit}
        onClose={(data) => handleUpdate(data)}
        name='note-edit'/>}

      {objectConfirm && <Modal
        isOpen={objectConfirm ? true : false}
        data={objectConfirm}
        onClose={(data) => handleConfirm(data)}
        name='confirm'/>}
    </>
  );
};
