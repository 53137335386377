import React, { useState } from 'react';
import { Link } from "react-router-dom"

import { signIn } from '../../../api/auth';
import { registerUser } from '../../../api/users';
import CircularProgress from "@material-ui/core/CircularProgress";
import { setToken } from "../../../api/token";

export const ClientRegistration = ({history}) => {
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState({});

  const handleChanges = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setClient({
      ...client,
      [key]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await(registerUser(client));
      const token = await(signIn(client.email, client.password));
      setToken(token);
      history.push('/dashboard');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    };
  };

  return (
      <section className="auth__popup">
          {loading ? (
            <CircularProgress />
          ) : (
            <form
              className="auth__form"
              onSubmit={handleSubmit}
            >
              <h1 className="auth__title">Client Atributes</h1>
                <div className="auth__two-fields">
                  <div className="auth__field">
                    <label
                      htmlFor="name"
                      className="auth__label"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      onChange={handleChanges}
                      value={client.firstName}
                      name="firstName"
                      placeholder="Name"
                      className="auth__input"
                    />
                  </div>
                  <div className="auth__field">
                    <label
                      htmlFor="lastname"
                      className="auth__label"
                    >
                      Lastname
                    </label>
                    <input
                      type="text"
                      id="lastname"
                      onChange={handleChanges}
                      value={client.lastName}
                      name="lastName"
                      placeholder="Lastname"
                      className="auth__input"
                    />
                  </div>
                </div>
                <div className="auth__field">
                  <label
                    htmlFor="address"
                    className="auth__label"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    onChange={handleChanges}
                    value={client.address}
                    placeholder="Address"
                    name="address"
                    className="auth__input"
                  />
                </div>
                <div className="auth__field">
                  <label
                    htmlFor="phone"
                    className="auth__label"
                  >
                    Phone
                  </label>
                  <input
                    type="phone"
                    id="phone"
                    onChange={handleChanges}
                    value={client.phoneNumber}
                    name="phoneNumber"
                    placeholder="Phone"
                    className="auth__input"
                  />
                </div>
                <div className="auth__field">
                  <label
                    htmlFor="email"
                    className="auth__label"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    onChange={handleChanges}
                    value={client.email}
                    name="email"
                    placeholder="Email"
                    className="auth__input"
                    required
                  />
                </div>
                <div className="auth__two-fields">
                  <div className="auth__field">
                    <label
                      htmlFor="birth"
                      className="auth__label"
                    >
                      Birth Date
                    </label>
                    <input
                      type="text"
                      id="birth"
                      onChange={handleChanges}
                      value={client.birthDate}
                      name="birthDate"
                      className="auth__input"
                    />
                  </div>
                  <div className="auth__field">
                    <label
                      htmlFor="enrollment"
                      className="auth__label"
                    >
                      Enrollment Date
                    </label>
                    <input
                      type="text"
                      id="enrollment"
                      onChange={handleChanges}
                      value={client.registrationDate}
                      name="registrationDate"
                      className="auth__input"
                    />
                  </div>
                </div>
                <div className="auth__field">
                  <label
                    htmlFor="insurance"
                    className="auth__label"
                  >
                    Insurance Info
                  </label>
                  <input
                    type="text"
                    id="insuranse"
                    onChange={handleChanges}
                    name="info"
                    value={client.info}
                    placeholder="Insurance"
                    className="auth__input"
                  />
                </div>
                <div className="auth__field">
                  <label
                    htmlFor="diagnosis"
                    className="auth__label"
                  >
                    Diagnosis Code
                  </label>
                  <input
                    type="text"
                    id="diagnosis"
                    onChange={handleChanges}
                    value={client.diagnosis}
                    name="diagnosis"
                    placeholder="Diagnosis"
                    className="auth__input"
                  />
                </div>
                <div className="auth__field">
                  <label
                    htmlFor="refferal"
                    className="auth__label"
                  >
                    Referral type
                  </label>
                  <input
                    type="text"
                    id="referal"
                    onChange={handleChanges}
                    value={client.referralType}
                    name="referralType"
                    placeholder="Refferal Type"
                    className="auth__input"
                  />
                </div>
                <div className="auth__links">
                  <Link to="/restore-password" className="auth__link">
                    I have account
                  </Link>
                  <button
                    type="submit"
                    className="auth__button registration__button"
                    disabled={!client.firstName || !client.lastName || !client.email}
                  >
                    Next
                  </button>
                </div>
            </form>
          )}
      </section>
  );
};
