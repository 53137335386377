import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom"

import './style.css';

import { images } from '../../helper';
import { selectors, actions } from '../../redux/store';

const Aside = () => {
    const aside = useSelector(selectors.getIsAsideActive);
    const currentUser = useSelector(selectors.getCurrentUser);

    const dispatch = useDispatch();

    return (
        <aside className={aside ? "aside-active" : "aside-hidden"}>
            <div className="aside">
                <div className="header header-aside">
                    <button
                        type="button"
                        className="header-aside__user"
                        onClick={() => {
                            dispatch(actions.setIsUserActive(currentUser));
                        }}

                    >
                        <img src={images.doctor} alt="provider" />
                    </button>
                    <Link to="/dashboard" className="logoAside">
                        <img
                            src={images.logo}
                            alt="logo"
                            className="logo__img"
                        />
                        <h1 className="logo__title">
                            iPractice
                        </h1>
                    </Link>
                    <div
                        className="exit"
                        onClick={() => dispatch(actions.setIsAsideActive(!aside))}
                        title={'Side menu'}
                    >
                        <img src={images.exit} alt="exit" />
                    </div>
                </div>
                <div className="aside__content">
                    <div className="navigation-column">
                        <ul>
                            <li>
                                <Link
                                    to='/dashboard'
                                    title="home"
                                    className="navigation-item"
                                    onClick={() => dispatch(actions.setIsAsideActive(false))}
                                >
                                    <img
                                        src={images.home}
                                        alt="sessions"
                                        className="navigation-item__icon"
                                    />
                                    <p className="navigation-item__text">
                                        Home
                                    </p>
                                </Link>
                            </li>
                            <li>
                                {currentUser.type === 'PROVIDER' ? (
                                    <Link
                                        to='/clients'
                                        title="clients"
                                        className="navigation-item"
                                        onClick={() => dispatch(actions.setIsAsideActive(false))}
                                    >
                                        <img
                                            src={images.people}
                                            alt="clients"
                                            className="navigation-item__icon"
                                            />
                                        <p className="navigation-item__text">
                                            Clients
                                        </p>
                                    </Link>
                                ) : (
                                    <Link
                                        to='/providers'
                                        title="providers"
                                        className="navigation-item"
                                        onClick={() => dispatch(actions.setIsAsideActive(false))}
                                    >
                                        <img
                                            src={images.people}
                                            alt="providers"
                                            className="navigation-item__icon"
                                            />
                                        <p className="navigation-item__text">
                                            Providers
                                        </p>
                                    </Link>
                                )}
                            </li>
                            <li>
                                <Link
                                    to='/sessions'
                                    title="sessions"
                                    className="navigation-item"
                                    onClick={() => dispatch(actions.setIsAsideActive(false))}
                                >
                                    <img
                                        src={images.dashboard}
                                        alt="sessions"
                                        className="navigation-item__icon"
                                    />
                                    <p className="navigation-item__text">
                                        Sessions
                                    </p>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/scheduling'
                                    title="scheduling"
                                    className="navigation-item"
                                    onClick={() => dispatch(actions.setIsAsideActive(false))}
                                >
                                    <img
                                        src={images.scheduling}
                                        alt="scheduling"
                                        className="navigation-item__icon"
                                        />
                                    <p className="navigation-item__text">
                                        Scheduling
                                    </p>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/todo'
                                    title="todo"
                                    className="navigation-item"
                                    onClick={() => dispatch(actions.setIsAsideActive(false))}
                                >
                                    <img
                                        src={images.todo}
                                        alt="todo"
                                        className="navigation-item__icon"
                                    />
                                    <p className="navigation-item__text">
                                        To Do
                                    </p>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/forms'
                                    title="forms"
                                    className="navigation-item"
                                    onClick={() => dispatch(actions.setIsAsideActive(false))}
                                >
                                    <img
                                        src={images.forms}
                                        alt="forms"
                                        className="navigation-item__icon"
                                    />
                                    <p className="navigation-item__text">
                                        Forms
                                    </p>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/invoice'
                                    title="invoice"
                                    className="navigation-item"
                                    onClick={() => dispatch(actions.setIsAsideActive(false))}
                                >
                                    <img
                                        src={images.money}
                                        alt="invoice"
                                        className="navigation-item__icon"
                                    />
                                    <p className="navigation-item__text">
                                        Invoice
                                    </p>
                                </Link>
                            </li>
                            {currentUser.type === 'PROVIDER' && (
                                <li>
                                    <Link
                                        to='/'
                                        title="analitycs"
                                        className="navigation-item"
                                        onClick={() => dispatch(actions.setIsAsideActive(false))}
                                    >
                                        <img
                                            src={images.analitycs}
                                            alt="report"
                                            className="navigation-item__icon"
                                        />
                                        <p className="navigation-item__text">
                                            Analitycs
                                        </p>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div
                        className="navigation-item aside-footer"
                        onClick={() => {
                            dispatch(actions.setIsUserActive(currentUser));
                        }}
                    >
                        <div className="aside-footer__left">
                            <button
                                type="button"
                            >
                                <img
                                    src={images.doctor}
                                    alt="doctor"
                                    className="aside-footer__doctor-icon"
                                />
                            </button>
                            <div className="aside-footer__text">
                                <p className="aside-footer__name">
                                    {`${currentUser.firstName} ${currentUser.lastName}`}
                                </p>
                                <p className="aside-footer__position">
                                    {currentUser.type}
                                </p>
                            </div>
                        </div>
                        <button
                            type="button"
                            onClick={() => {
                                dispatch(actions.setIsUserActive(currentUser));
                            }}
                            className="aside-footer__options"
                        >
                            <img src={images.options} alt="options"/>
                        </button>
                    </div>
                </div>

            </div>
        </aside>
    )
}
export default Aside;