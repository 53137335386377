import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/en-au';

import { updateTodo } from '../../../../api/todo';
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

moment.locale('en-au');

export const TodoEdit = ({data, handleClose}) => {
  const [selectedTodo, setSelectedTodo] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const locale = 'en-au';

  const handleChanges = (event) => {
    setSelectedTodo({
      ...selectedTodo,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChanges = (date) => {
    setSelectedTodo({
      ...selectedTodo,
      deadline: date,
    });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await(updateTodo(selectedTodo));
      setIsLoading(false);
      handleClose(null, selectedTodo);
    } catch (error) {
      console.log(error);
    };
  };

  return (
    <>
      <div className="modal__field">
        <label
          htmlFor="title"
          className="modal__secondary-title"
        >
          Title
        </label>
        <input
          type="text"
          id="title"
          onChange={handleChanges}
          value={selectedTodo.title}
          name="title"
          placeholder="Title"
          className="modal__input"
        />
      </div>
      <div className="modal__field">
        <label
          htmlFor="text"
          className="modal__secondary-title"
        >
          Text
        </label>
        <textarea
          type="text"
          id="text"
          onChange={handleChanges}
          value={selectedTodo.text}
          name="text"
          placeholder="Text"
          className="modal__textarea"
        ></textarea>
      </div>
      <div className="modal__two-fields">
        <div className="modal__field">
          <label
            htmlFor="deadline"
            className="modal__secondary-title"
          >
            Deadline
          </label>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale={locale}
            libInstance={moment}
          >
            <KeyboardDatePicker
              id="deadline"
              onChange={handleDateChanges}
              value={selectedTodo.deadline}
              name="deadline"
              className="modal__datepicker"
              variant="inline"
              format="MM/DD/yyyy"
              disableToolbar
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="modal__field">
          <label
            htmlFor="priority"
            className="modal__secondary-title"
          >
            Priority
          </label>
          <Select
            type="text"
            id="priority"
            onChange={handleChanges}
            value={selectedTodo.priority}
            name="priority"
            className="modal__input"
            placeholder="Priority"
          >
            <MenuItem value="" disabled>Choose priority</MenuItem>
            <MenuItem value="MAJOR">Major</MenuItem>
            <MenuItem value="NORMAL">Normal</MenuItem>
          </Select>
        </div>
      </div>
      <div className="modal__buttons">
        <button
          type="button"
          className="modal__button"
          onClick={() => handleSave()}
          disabled={!selectedTodo.title && !selectedTodo.text}
        >
          {isLoading ? (
            <CircularProgress className="modal__button-progress"/>
          ) : 'Save'}
        </button>
      </div>
    </>
  );
};
