const tokenName = 'token';

export const getToken = () => {
    const data = localStorage.getItem(tokenName);

    if(!data) return ;

    const token = JSON.parse(data)

    return `${token.tokenType} ${token.accessToken}`;
}

export const setToken = (token) => {
    if (token && typeof token === 'object') {
        return localStorage.setItem(tokenName, JSON.stringify(token));
    };
};

export const clearToken = () => {
    localStorage.removeItem(tokenName);
    window.location.reload();

    return false;
};
