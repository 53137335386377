import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectors, actions } from '../../redux/store';
import { TodoList } from './List';
import { getAllTodos } from '../../api/todo';
import { images } from '../../helper';
import { Modal } from '../Modal';
import CircularProgress from "@material-ui/core/CircularProgress";


export const Todo = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const todos = useSelector(selectors.getTodos);
    const query = useSelector(selectors.getQuery);
    const dispatch = useDispatch();
    const [objectTodoCreate, setObjectTodoCreate] = useState(null);

    useEffect(() => {
        loadTodos(dispatch);
    }, [dispatch]);

    const loadTodos = async (disp) => {
        try {
            setIsLoading(true);
            setError(null);
            const todos = await(getAllTodos());
            disp(actions.setTodos(todos));
            setIsLoading(false);
            console.log(todos);
        } catch (error) {
            console.log(error);
        };
    };

    const handleChages = (event) => {
        setError(null);
        dispatch(actions.setQuery(event.target.value));
    };

    const handleUpdate = (data) => {
      if (data) {
        loadTodos(dispatch);
      };
  
      setObjectTodoCreate(null);
    };

    return (
        <>
            <section className="header">
                <div className="header__left">
                    <div className="header__pic">
                        <img src={images.sessions_blue} alt="sessions" />
                    </div>
                    <h1 className="header__title">
                        Todo list
                    </h1>
                </div>
                <div className="header__right">
                    <button
                        type="button"
                        className="header__button flex-button"
                        onClick={() => setObjectTodoCreate(true)}
                        title="Add new todo"
                    >
                        <img src={images.add} alt="add todo" className="flex-button__img" />
                        <p className="flex-button__text">
                            Create todo
                        </p>
                    </button>
                </div>
            </section>

            <section className="sessions__nav">
                <input
                    className="header__search"
                    type="text"
                    placeholder="Search"
                    value={query}
                    onChange={handleChages}
                />
            </section>
            {isLoading ? (
                <CircularProgress className="progress" />
            ) : error ? (
                    <p className="message">{error}</p>
                ) :
                    todos.length !== 0 ? (
                        <TodoList loadTodos={loadTodos} />
                    ) : (
                        <p className="message">You don't have todos yet</p>
            )}

            {objectTodoCreate && <Modal
                isOpen={objectTodoCreate ? true : false}
                data={objectTodoCreate}
                onClose={(data) => handleUpdate(data)}
                name='todo-create' />}
        </>
    );
};
