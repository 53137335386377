import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './style.css';

import { selectors } from '../../../../redux/store';
import { createForm, createFormPDF } from '../../../../api/forms';
import { getAllUsers } from '../../../../api/users';
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';

export const FormUpload = ({ handleClose }) => {
  const currentUser = useSelector(selectors.getCurrentUser);
  const [form, setForm] = useState({providerId: currentUser.id, signed: true});
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [clients, setClients] = useState([]);
  const templates = [
    {name: "Insurance information", id: 6},
    {name: "Intake forms", id: 7},
    {name: "Supervisee information form", id: 8},
    {name: "Supervision contract", id: 9},
  ];

  const loadClients = async () => {
    try {
      const clients = await(getAllUsers());
      setClients(clients);
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    if (currentUser.type === 'PROVIDER') {
      loadClients();
    } else {
      setForm({
        ...form,
        clientId: currentUser.id,
      });
    };
  // eslint-disable-next-line
  }, [currentUser.type]);

  const handleClient = (newClient) => {
    if (newClient) {
      setForm({
        ...form,
        clientId: newClient && newClient.id,
      });
    };
  };

  const handleTemplate = (template) => {
    if (template) {
      setForm({
        ...form,
        templateName: template.name,
        templateId: template.id,
      });
    };
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const newForm = await(createForm(form));
      await(createFormPDF(newForm, file));
      setIsLoading(false);
      handleClose(null, form);
    } catch (error) {
      console.log(error);
    };
  };

  const handleFile = (event) => {
    event.preventDefault();

    let reader = new FileReader();
    let uploadedFile = event.target.files[0];

    reader.onloadend = () => {
      setFile(uploadedFile);
    };

    reader.readAsDataURL(uploadedFile);
    console.log(file);
  }

  return (
    <>
      <div className="modal__two-fields">
        {currentUser.type === 'PROVIDER' && (
          <div className="modal__field">
            <Autocomplete
              className="modal__autocomplete"
              id="client-choose"
              getOptionSelected={(option, value) => option.firstName === value.firstName}
              options={clients}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
              style={{ width: '100%' }}
              onChange={(event, newClient) => handleClient(newClient)}
              renderInput={(params) => <TextField {...params} label="Choose client" variant="outlined" />}
            />
          </div>
        )}
        <div className="modal__field">
          <Autocomplete
            className="modal__autocomplete"
            id="template-choose"
            getOptionSelected={(option, value) => option.name === value.name}
            options={templates}
            getOptionLabel={(option) => `${option.name}`}
            style={{ width: '100%' }}
            onChange={(event, newTemplate) => handleTemplate(newTemplate)}
            renderInput={(params) => <TextField {...params} label="Choose form template" variant="outlined" />}
          />
        </div>
      </div>
      {form.templateId && (
        <div className="modal__field">
          <a
            href={
              (form.templateId === 6 && ("assets/forms/insurance_information_form.pdf"))
                ||  (form.templateId === 7 && ("assets/forms/IntakeForms.pdf"))
                ||  (form.templateId === 8 && ("assets/forms/SuperviseeInformationForm.pdf"))
                ||  (form.templateId === 9 && ("assets/forms/SupervisionContract.pdf"))
            }
            download
            className="modal__link"
          >
            <p className="button__text">
                {`Download blank (${form.templateName})`}
            </p>
          </a>
        </div>
      )}
      {(form.clientId && form.templateId) && (
        <div className="upload">
          <input
            type="file"
            name="file"
            onChange={handleFile}
            className="upload__toggler"
          />
          <label
            htmlFor="file"
            className="upload__label"
          >
            <button className="upload__button">
              Choose a file
            </button>
            <span className="upload__file-name">
              {file ? file.name : 'File not chosen'}
            </span>
          </label>
      </div>
      )}
      <div className="modal__buttons">
        <button
          type="button"
          className="modal__button"
          onClick={handleSave}
          disabled={!form.clientId || !form.templateId}
        >
          {isLoading ? (
            <CircularProgress className="modal__button-progress"/>
          ) : 'Submit'}
        </button>
      </div>
    </>
  );
};