import React, { useState } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import 'moment/locale/en-au';

import { updateUser } from '../../../../api/users';
import { errorClass } from '../../../../helper';
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

moment.locale('en-au');

export const ProfileSettingsClient = ({data, handleClose}) => {
  const [selectedClient, setSelectedClient] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [formErrors, setFormErrors] = useState({phoneNumber: '',});
  const locale = 'en-au';

  const handleChanges = (event) => {
    if (event.target.value === "true") {
      setSelectedClient({
        ...selectedClient,
        [event.target.name]: true,
      });
    } else if (!isNaN(+event.target.value)) {
      setSelectedClient({
        ...selectedClient,
        [event.target.name]: +event.target.value,
      });
    } else {
      setSelectedClient({
        ...selectedClient,
        [event.target.name]: event.target.value,
      });
    };
    validateField(event.target.name, event.target.value);
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let phoneValid = isPhoneValid;
    switch(fieldName) {
      case 'phoneNumber':
        phoneValid = value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
        fieldValidationErrors.phone = phoneValid ? '' : 'Phone number is invalid';
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setIsPhoneValid(phoneValid);
  };

  const handleDateChanges = (date) => {
    setSelectedClient({
      ...selectedClient,
      birthDate: date,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setIsLoading(true);
      await(updateUser(selectedClient));
      handleClose(null, selectedClient);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    };
  };

  return (
    <>
      <div className="modal__two-fields">
        <div className="modal__field">
          <label
            htmlFor="name"
            className="modal__secondary-title"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            onChange={handleChanges}
            value={selectedClient.firstName}
            name="firstName"
            placeholder="Name"
            className="modal__input"
          />
        </div>
        <div className="modal__field">
          <label
            htmlFor="lastname"
            className="modal__secondary-title"
          >
            Lastname
          </label>
          <input
            type="text"
            id="lastname"
            onChange={handleChanges}
            value={selectedClient.lastName}
            name="lastName"
            placeholder="Lastname"
            className="modal__input"
          />
        </div>
      </div>
      <div className="modal__field">
        <label
          htmlFor="address"
          className="modal__secondary-title"
        >
          Address
        </label>
        <input
          type="text"
          id="address"
          onChange={handleChanges}
          value={selectedClient.address}
          placeholder="Address"
          name="address"
          className="modal__input"
        />
      </div>
      <div className="modal__field">
        <label
          htmlFor="phone"
          className="modal__secondary-title"
        >
          Phone
        </label>
        <input
          type="phone"
          id="phone"
          onChange={handleChanges}
          value={selectedClient.phoneNumber}
          name="phoneNumber"
          placeholder="Phone"
          className={`modal__input ${errorClass(formErrors.phoneNumber)}`}
        />
        <p class="error-message">
          {formErrors.phoneNumber}
        </p>
      </div>
      {/* <div className="modal__field">
        <label
          htmlFor="email"
          className="modal__secondary-title"
        >
          Email
        </label>
        <input
          type="text"
          id="email"
          onChange={handleChanges}
          value={selectedClient.email}
          name="email"
          placeholder="Email"
          className="modal__input"
          required
        />
      </div> */}
      <div className="modal__two-fields">
        <div className="modal__field">
          <label
            htmlFor="birth"
            className="modal__secondary-title"
          >
            Birth Date
          </label>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale={locale}
            libInstance={moment}
          >
            <KeyboardDatePicker
              id="birth"
              onChange={handleDateChanges}
              value={selectedClient.birthDate}
              name="birthDate"
              className="modal__datepicker"
              variant="inline"
              format="MM/DD/yyyy"
              disableToolbar
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="modal__field">
          <label
            htmlFor="gender"
            className="modal__secondary-title"
          >
            Gender
          </label>
          <Select
            type="text"
            id="gender"
            onChange={handleChanges}
            value={selectedClient.gender}
            name="gender"
            className="modal__input"
            placeholder="Gender"
          >
            <MenuItem value="" disabled>Choose gender</MenuItem>
            <MenuItem value="MALE">Male</MenuItem>
            <MenuItem value="FEMALE">Female</MenuItem>
          </Select>
        </div>
      </div>
      {/* <div className="modal__two-fields">
        <div className="modal__field">
          <label
            htmlFor="insurance-name"
            className="modal__secondary-title"
          >
            Insurance company name
          </label>
          <input
            type="text"
            id="insuranse-name"
            onChange={handleChanges}
            name="info"
            value={selectedClient.info}
            placeholder="Company"
            className="modal__input"
          />
        </div> */}
        {/* <div className="modal__field">
          <label
            htmlFor="insurance-code"
            className="modal__secondary-title"
          >
            Insurance code
          </label>
          <input
            type="text"
            id="insuranse-code"
            onChange={handleChanges}
            name="code"
            value={selectedClient.code}
            placeholder="Code"
            className="modal__input"
          />
        </div>
      </div> */}
      {/* <div className="modal__field">
        <label
          htmlFor="diagnosis"
          className="modal__secondary-title"
        >
          Diagnosis code
        </label>
        <input
          type="text"
          id="diagnosis"
          onChange={handleChanges}
          value={selectedClient.diagnosis}
          name="diagnosis"
          placeholder="Diagnosis"
          className="modal__input"
        />
      </div> */}
      <div className="modal__field">
        <label
          htmlFor="refferal"
          className="modal__secondary-title"
        >
          Referral type
        </label>
        <input
          type="text"
          id="referal"
          onChange={handleChanges}
          value={selectedClient.referralType}
          name="referralType"
          placeholder="Refferal Type"
          className="modal__input"
        />
      </div>
      {/* <div className={selectedClient.paymentTypeVerified === 'COPAY' && "modal__two-fields"}>
        <div className="modal__field">
          <label
            htmlFor="payment"
            className="modal__secondary-title"
          >
            Payment type
          </label>
          <Select
            type="text"
            id="payment"
            onChange={handleChanges}
            value={selectedClient.paymentTypeVerified}
            name="paymentTypeVerified"
            className="modal__input"
            placeholder="Payment"
          >
            <MenuItem value="" disabled>Choose payment</MenuItem>
            <MenuItem value="INSURANCE">Insurance</MenuItem>
            <MenuItem value="COPAY">CoPay</MenuItem>
            <MenuItem value="SELFPAY">Selfpay</MenuItem>
            <MenuItem value="UNSET">Unset</MenuItem>
          </Select>
        </div>
        {selectedClient.paymentTypeVerified === "COPAY" && (
          <div className={(!selectedClient.coPay || (selectedClient.coPay <= 0)) ? "modal__field modal__field--error" : "modal__field"}>
            <label
              htmlFor="copay"
              className="modal__secondary-title"
            >
              CoPay
            </label>
            <input
              type="text"
              id="copay"
              onChange={handleChanges}
              value={selectedClient.coPay}
              name="coPay"
              className="modal__input"
              placeholder="Copay amount"
            />
          </div>
        )}
        
      </div> */}
      {/* <div className="modal__field">
        <label
          htmlFor="forms"
          className="modal__secondary-title"
        >
          Forms verified
        </label>
        <div className="modal__checkbox">
          <label
            htmlFor="forms-yes"
            className="modal__secondary-title"
          >
            Yes
          </label>
            <input
              type="radio"
              id="forms-yes"
              onChange={handleChanges}
              value={true}
              name="formsVerified"
              checked={selectedClient.formsVerified}
              className="modal__input"
            />
          <label
            htmlFor="forms-no"
            className="modal__secondary-title"
          >
            No
          </label>
          <input
              type="radio"
              id="forms-no"
              onChange={handleChanges}
              value={false}
              name="formsVerified"
              checked={!selectedClient.formsVerified}
              className="modal__input"
            />
        </div>
      </div> */}
      <div className="modal__buttons">
        <button
          type="submit"
          className="modal__button"
          onClick={(event) => handleSubmit(event)}
          disabled={selectedClient.paymentTypeVerified === 'COPAY' && (!selectedClient.coPay || (selectedClient.coPay === 0))}
        >
          {isLoading ? (
            <CircularProgress className="modal__button-progress" />
          ) : 'Save Changes'}
        </button>
      </div>
    </>
  );
};
