import { SignIn } from "../components/Authentication/SignIn";
import { RestorePassword } from '../components/Authentication/PasswordRestore';
import { Registration } from '../components/Authentication/RegistrationProvider';
import { ClientRegistration } from '../components/Authentication/RegistrationClient';
import { CheckEmail } from '../components/Authentication/CheckEmail';
import { CreatePassword } from "../components/Authentication/PasswordCreate";

export default [
    {path:'/signin', component: SignIn, default: true},
    {path:'/restore-password', component: RestorePassword},
    {path:'/email', component: CheckEmail},
    {path:'/registration', component: Registration},
    {path:'/client-registration', component: ClientRegistration},
    {path:'/create-password:token', component: CreatePassword},
];
