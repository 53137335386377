import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core'

import App from './App';
import store from './redux/store';
import theme from './themes/theme';

ReactDOM.render(
    <React.StrictMode>
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <HashRouter>
                    <App/>
                </HashRouter>
            </Provider>
        </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
