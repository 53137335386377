import { request } from './api';

export const getAllSessions = () => {
  return request(`/session/find-all`, {
  });
};

export const getProviderSessions = (id) => {
  return request(`/session/find-by-provider?provider_id=${id}`, {});
};

export const getClientSessions = (clientId, providerId) => {
  return request(`/session/find-by-provider-and-client?provider_id=${providerId}&client_id=${clientId}`, {
  });
};

export const getSession = (id) => {
  return request(`/session/${id}`, {
  });
};

export const deleteSession = (id) => {
  return request(`/session/${id}`, {
    method: 'DELETE',
  });
};

export const updateSession = (session) => {
  return request(`/session`, {
    method: 'PUT',
    body: JSON.stringify(session),
  });
};

export const createSession = (session) => {
  return request(`/session`, {
    method: 'POST',
    body: JSON.stringify(session),
  });
};

export const checkSession = (session) => {
  return request(`/session/check-session`, {
    method: 'POST',
    body: JSON.stringify(session),
  });
};
