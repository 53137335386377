import React, { useState, useRef } from 'react';

import { createForm, createFormPDF } from '../../../../api/forms';
import { Insurance } from '../../../Forms/Templates/Insurance';
import { Intake } from '../../../Forms/Templates/Intake';
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export const FormCreateTemplate = ({ data, handleClose }) => {
  const [form, setForm] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState(null);
  // eslint-disable-next-line
  const [pdf, setPdf] = useState(new jsPDF("p", "mm"));
  const template = useRef(null);

  const handleChanges = (event) => {
    setForm({
      ...form,
      htmlData: {
        ...form.htmlData,
        [event.target.name]: event.target.value,
      },
    });
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      await(createForm(form));
      setIsLoading(false);
      handleClose(null, form);
    } catch (error) {
      console.log(error);
    };
  };

  const handleSendPDF = () => {
    setIsLoading(true);

    const copyDom = template.current.cloneNode(true);

    document.body.appendChild(copyDom);
    copyDom.classList.add('template__copy')
    convertToPDF(copyDom);

    const timer = setTimeout(async () => {
      try {
        const newForm = await(createForm({...form, signed: true}));
        await(createFormPDF(newForm, dataURItoBlob(pdf.output('datauristring'))));
      } catch (error) {
        console.log(error);
      };
      setIsLoading(false);
      handleClose(null, form);
    }, 10000);

    return () => clearTimeout(timer);
  };

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], {type: mimeString});

    return blob;
  }

  const convertToPDF = (input) => {
    input.style.backgroundColor = '#ffffff';
    input.style.width = '210mm';

    html2canvas(input, {scrollX:0, scrollY: -window.scrollY})
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210; 
        const pageHeight = 295;  
        const imgHeight = canvas.height * imgWidth / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        };
      });
  };

  return (
   <>
      {form.templateId === 6 && (
        <Insurance
          formData={form.htmlData}
          clientId={form.clientId}
          handleChanges={handleChanges}
          ref={template}
          signatureUrl={signatureUrl}
          setSignatureUrl={setSignatureUrl}
        />
      )}

      {form.templateId === 7 && (
        <Intake
          formData={form.htmlData}
          handleChanges={handleChanges}
          ref={template}
        />
      )}

      <div className="modal__buttons">
        <button
          type="button"
          className="modal__button"
          onClick={handleSave}
        >
          {isLoading ? (
            <CircularProgress className="modal__button-progress"/>
          ) : 'Save'}
        </button>
        <button
          type="button"
          className="modal__button"
          onClick={handleSendPDF}
          disabled={!signatureUrl}
        >
          {isLoading ? (
            <CircularProgress className="modal__button-progress"/>
          ) : 'Generate PDF'}
        </button>
      </div>
    </>
  );
};