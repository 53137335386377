import React, { useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import CircularProgress from "@material-ui/core/CircularProgress";
import { PUBLIC_KEY, createPayment, confirmPayment } from '../../../../api/payment';
import { Modal } from '../../../Modal';

const CheckoutForm = ({ data, handleClose }) => {
  const stripe = useStripe();
  const elements = useElements();
  const paymentDetails = {amount: data.amount, currency: 'USD', session: data.session};
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [objectMessage, setObjectMessage] = useState(null);

  const  handleServerResponse = async (responseJson) => {
    if (responseJson.error) {
      console.log(responseJson.error);
      setError(responseJson.error);
    } else if (responseJson.requiresAction) {
      const response = await stripe.handleCardAction(responseJson.clientSecret);
        if (response.error) {
          console.log(response.error);
          setError(response.error);
        } else {
          console.log(response);
          try {
              const res = await confirmPayment({...paymentDetails, paymentIntentId: response.paymentIntent.id,});
              handleServerResponse(res);
          } catch (error) {
            console.log(error);
            setError(error);
          };
        }
    } else {
      setObjectMessage('Payment recieved. Session was saved successfuly!');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (error) {
        console.log(error);
        setError(error);
        setIsLoading(false);
      } else {
  
        const response = await createPayment({...paymentDetails, paymentMethodId: paymentMethod.id});
        handleServerResponse(response);
      }
    } catch (error) {
      console.log(error);
      setError(error);
    };
    console.log(paymentDetails);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="book__block">
        <h3 className="modal__secondary-title">
          Price
          <span className="highlighted">{` ${data.amount}$`}</span>
        </h3>
      </div>
      <div className="book__block">
        <CardElement className="modal__input" />
      </div>
      {error && (
        <p className="message" style={{color: "#EE3D72"}}>
          {(error.message === '404' || error.message === '400') ? 'Something went wrong. Try again please' : error.message}
        </p>
      )}
      <button
        type="submit"
        className="modal__button"
      >
        {isLoading ? (
          <CircularProgress className="modal__button-progress" />
        ) : "Pay"}
      </button>

      {objectMessage && <Modal
        isOpen={objectMessage ? true : false}
        data={objectMessage}
        onClose={(data) => handleClose(null, data)}
        name='message'/>}
    </form>
  );
};

const stripePromise = loadStripe(PUBLIC_KEY);

export const Payment = ({ data, handleClose }) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm data={data} handleClose={handleClose} />
  </Elements>
);
