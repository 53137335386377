import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.css';

import { selectors, actions } from '../../redux/store';
import { SessionsList } from './List';
import { getAllSessions } from '../../api/sessions';
import { images } from '../../helper';
import { Modal } from '../Modal';
import CircularProgress from "@material-ui/core/CircularProgress";

const moment = require('moment');

export const Sessions = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [objectSessionCreate, setObjectSessionCreate] = useState(null);
    const sessions = useSelector(selectors.getSessions);
    const query = useSelector(selectors.getQuery);
    const currentUser = useSelector(selectors.getCurrentUser);
    const dispatch = useDispatch();

    useEffect(() => {
        loadSessions(dispatch);
    }, [dispatch]);

    const loadSessions = async (disp) => {
        try {
            setIsLoading(true);
            setError(null);
            const session = await(getAllSessions());
            disp(actions.setSessions(session));
            setIsLoading(false);
        } catch (error) {
            console.log(error);
        };
    };

    const handleChages = (event) => {
        setError(null);
        dispatch(actions.setQuery(event.target.value));
    };

    const handleUpdate = (data) => {
        if (data) {
          loadSessions(dispatch);
        };
    
        setObjectSessionCreate(null);
      };

    return (
        <>
            <section className="header">
                <div className="header__left">
                    <div className="header__pic">
                        <img src={images.sessions_blue} alt="sessions" />
                    </div>
                    <h1 className="header__title">
                        Sessions
                    </h1>
                </div>
                <div className="header__right">
                    <button
                        disabled={currentUser.type === 'CLIENT' && (!currentUser.formsVerified || !currentUser.paymentTypeVerified)}
                        type="button"
                        className="header__button flex-button"
                        onClick={() => setObjectSessionCreate({date: moment(), client: null})}
                        title={(currentUser.type === 'CLIENT' && (!currentUser.formsVerified || !currentUser.paymentTypeVerified))
                            ? "You can't book the session untill forms and payment type are verified"
                            : "Book a session"}
                    >
                        <img src={images.add} alt="add session" className="flex-button__img" />
                        <p className="flex-button__text">
                            Book a session
                        </p>
                    </button>
                </div>
            </section>

            <section className="sessions__nav">
                <input
                    className="header__search"
                    type="text"
                    placeholder="Search"
                    value={query}
                    onChange={handleChages}
                />
            </section>
            {isLoading ? (
                <CircularProgress className="progress" />
            ) : error ? (
                    <p className="message">{error}</p>
                ) :
                    sessions.length !== 0 ? (
                        <SessionsList loadSessions={loadSessions} />
                    ) : (
                        <p className="message">You don't have sessions yet</p>
            )}

            {objectSessionCreate && <Modal
                isOpen={objectSessionCreate ? true : false}
                data={objectSessionCreate}
                onClose={(data) => handleUpdate(data)}
                name='session-create'/>}
        </>
    );
};
