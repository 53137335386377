import React from 'react';
import { Link } from "react-router-dom"

import { images } from '../../../helper';

export const CheckEmail = () => {

  return (
      <section className="auth__popup">
        <div className="auth__form check-email">
          <img src={images.email} alt="email"/>
          <h1 className="auth__title">
            Check your email
          </h1>
          <a href="/" className="auth__link check-email__link">
            email@example.com
          </a>
          <Link to="/signin" className="auth__link auth__link--light">
            {`< Back to Sign In`}
          </Link>
        </div>
      </section>
  )
}
