import React from 'react';

import './style.css';

export const Pagination = ({ page, setPage, itemsQuantity, pageStart, setPageStart, pageEnd, setPageEnd }) => {

  const totalPages = Math.ceil(itemsQuantity / 10);

  const handlePrev = () => {
    setPage(page - 1);
    setPageStart(pageStart - 10);
    setPageEnd(pageEnd - 10);
    console.log(page);
  };

  const handleNext = () => {
    setPage(page + 1);
    setPageStart(pageStart + 10);
    setPageEnd(pageEnd + 10);
    console.log(page);
  };

  return (
    <div className="pagination">
      <button
        className="pagination__button"
        type="button"
        onClick={() => handlePrev()}
        disabled={page === 1}
      >
        Prev
      </button>
      <span>
        {` ${page} / ${totalPages <= 0 ? '1' : totalPages} `}
      </span>
      <button
        className="pagination__button"
        type="button"
        onClick={() => handleNext()}
        disabled={(page === totalPages) || (itemsQuantity < 10)}
      >
        Next
      </button>
    </div>
  );
};