import { request } from './api';

export const getAllUsers = () => {

  return request(`/user/find-all`, {});
};

export const getUser = (id) => {

  return request(`/user/${id}`, {});
};

export const deleteUser = (id) => {
  return request(`/user/${id}`, {
    method: 'DELETE',
  });
};

export const updateUser = (user) => {
  return request(`/user`, {
    method: 'PUT',
    body: JSON.stringify(user),
  });
};


export const registerUser = (user) => {

  return request(`/user/register`, {
    method: 'POST',
    body: JSON.stringify(user),
  });
};

export const inviteUser = (providerId, client) => {
  return request(`/user/send-invite?providerId=${providerId}`, {
    method: 'POST',
    body: JSON.stringify(client),
  });
};

export const createUser = (providerId, client) => {
  return request(`/user/register/${providerId}`, {
    method: 'POST',
    body: JSON.stringify(client),
  });
};
