import { request } from './api';

export const signIn = (email, password) => {
  return request(`/auth/signin`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
    }),
  });
};

export const forgotPassword = (email) => {
  return request(`/auth/forgot-password?email=${email}`, {});
};

export const currentUserInfo = () => {
  return request(`/auth/info`, {});
};

export const changePassword = (password, token) => {
  return request(`/auth/change-password`, {
    method: 'POST',
    body: JSON.stringify({
      password,
      token,
    }),
  });
};

export const logout = () => {
  return request(`/auth/logout`, {
    method: 'POST',
  });
};
