import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// import momentTZ from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import 'moment/locale/en-au';

import { updateUser } from '../../../../api/users';
import { errorClass } from '../../../../helper';
import { actions } from '../../../../redux/store';
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

moment.locale('en-au');

export const ProfileSettingsProvider = ({data, handleClose}) => {
  const [selectedClient, setSelectedClient] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState({email: '', phoneNumber: '',});
  const locale = 'en-au';
  const dispatch = useDispatch();

  const handleChanges = (event) => {
    if (event.target.value === "true") {
      setSelectedClient({
        ...selectedClient,
        [event.target.name]: true,
      });
    } else if (event.target.value === "false") {
      setSelectedClient({
        ...selectedClient,
        [event.target.name]: false,
      });
    // } else if (!isNaN(+event.target.value)) {
    //   setSelectedClient({
    //     ...selectedClient,
    //     [event.target.name]: +event.target.value,
    //   });
    } else {
      setSelectedClient({
        ...selectedClient,
        [event.target.name]: event.target.value,
      });
    };
    validateField(event.target.name, event.target.value);
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let emailValid = isEmailValid;
    let phoneValid = isPhoneValid;
    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
        break;
      case 'phoneNumber':
        phoneValid = value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
        fieldValidationErrors.phoneNumber = phoneValid ? '' : 'Phone number is invalid';
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setIsEmailValid(emailValid);
    setIsPhoneValid(phoneValid);
    if (emailValid) {
      setIsFormValid(true);
    };
  };

  const handleDateChanges = (date) => {
    setSelectedClient({
      ...selectedClient,
      birthDate: date,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    try {
      setIsLoading(true);
      await(updateUser(selectedClient));
      dispatch(actions.setUser(selectedClient));
      handleClose(null, selectedClient);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    };
  };

  return (
    <>
    {/* {console.log(moment.tz.names())} */}
      <div className="modal__two-fields">
        <div className="modal__field">
          <label
            htmlFor="name"
            className="modal__secondary-title"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            onChange={handleChanges}
            value={selectedClient.firstName}
            name="firstName"
            placeholder="Name"
            className="modal__input"
          />
        </div>
        <div className="modal__field">
          <label
            htmlFor="lastname"
            className="modal__secondary-title"
          >
            Lastname
          </label>
          <input
            type="text"
            id="lastname"
            onChange={handleChanges}
            value={selectedClient.lastName}
            name="lastName"
            placeholder="Lastname"
            className="modal__input"
          />
        </div>
      </div>
      <div className="modal__field">
        <label
          htmlFor="address"
          className="modal__secondary-title"
        >
          Address
        </label>
        <input
          type="text"
          id="address"
          onChange={handleChanges}
          value={selectedClient.address}
          placeholder="Address"
          name="address"
          className="modal__input"
        />
      </div>
      <div className="modal__field">
        <label
          htmlFor="phone"
          className="modal__secondary-title"
        >
          Phone
        </label>
        <input
          type="phone"
          id="phone"
          onChange={handleChanges}
          value={selectedClient.phoneNumber}
          name="phoneNumber"
          placeholder="Phone"
          className={`modal__input ${errorClass(formErrors.phoneNumber)}`}
        />
        <p class="error-message">
          {formErrors.phoneNumber}
        </p>
      </div>
      <div className="modal__field">
        <label
          htmlFor="email"
          className="modal__secondary-title"
        >
          Email
        </label>
        <input
          type="text"
          id="email"
          onChange={handleChanges}
          value={selectedClient.email}
          name="email"
          placeholder="Email"
          className={`modal__input ${errorClass(formErrors.email)}`}
        />
        <p class="error-message">
          {formErrors.email}
        </p>
      </div>
      <div className="modal__two-fields">
        <div className="modal__field">
          <label
            htmlFor="birth"
            className="modal__secondary-title"
          >
            Birth Date
          </label>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            locale={locale}
            libInstance={moment}
          >
            <KeyboardDatePicker
              id="birth"
              onChange={handleDateChanges}
              value={selectedClient.birthDate}
              name="birthDate"
              className="modal__datepicker"
              variant="inline"
              format="MM/DD/yyyy"
              disableToolbar
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className="modal__field">
          <label
            htmlFor="gender"
            className="modal__secondary-title"
          >
            Gender
          </label>
          <Select
            type="text"
            id="gender"
            onChange={handleChanges}
            value={selectedClient.gender}
            name="gender"
            className="modal__input"
            placeholder="Gender"
          >
            <MenuItem value="" disabled>Choose gender</MenuItem>
            <MenuItem value="MALE">Male</MenuItem>
            <MenuItem value="FEMALE">Female</MenuItem>
          </Select>
        </div>
      </div>
      <div className="modal__buttons">
        <button
          type="submit"
          className="modal__button"
          onClick={(event) => handleSubmit(event)}
          disabled={(selectedClient.paymentTypeVerified === 'COPAY' && (!selectedClient.coPay || (selectedClient.coPay === 0))) || !isFormValid}
        >
          {isLoading ? (
            <CircularProgress className="modal__button-progress" />
          ) : 'Save Changes'}
        </button>
      </div>
    </>
  );
};
