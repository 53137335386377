import React, { useState } from 'react';

import { Page } from '../Page';
// import { Facesheet } from './Facesheet';
// import { MedicationList } from './MedicationList';
// import { SafetyAgreemnt } from './SafetyAgreement';
// import { Telehealth } from './Telehealth';
// import { Treatment } from './Treatment';
import { Modal } from '../../../Modal';

export const Intake = React.forwardRef(({ id, handleChanges, formData }, ref) => {
  const [objectFormSignature, setObjectFormSignature] = useState(null);
  // const [signatureUrl, setSignatureUrl] = useState(null);

  const handleSignature = (signature) => {

    // setSignatureUrl(signature)
    setObjectFormSignature(null);
  };

  return (
    <Page ref={ref}>
      {/* <Facesheet
        formData={formData}
        handleChanges={handleChanges}
        ref={ref}
      />
      <MedicationList 
        formData={formData}
        handleChanges={handleChanges}
        ref={ref}
      />
      <Telehealth
        formData={formData}
        handleChanges={handleChanges}
        ref={ref}
      />
      <Treatment
        formData={formData}
        handleChanges={handleChanges}
        ref={ref}
      />
      <SafetyAgreemnt
        formData={formData}
        handleChanges={handleChanges}
        ref={ref}
      /> */}
      {objectFormSignature && <Modal
        isOpen={objectFormSignature ? true : false}
        data={objectFormSignature}
        onClose={(data) => handleSignature(data)}
        name='form-signature'/>}
    </Page>
  );
});
