import React from 'react';

export const Message = ({data, handleClose}) => {

  return (
    <>
      <div className="modal__field">
        <p className="message">
          {data}
        </p>
      </div>
      <div className="modal__buttons">
        <button
          type="button"
          onClick={(event) => handleClose(event, true)}
          className="modal__button"
        >
          Ok
        </button>
      </div>
    </>
  );
};
