import React, { useState } from 'react';
import { Link } from "react-router-dom"

import { forgotPassword } from '../../../api/auth';
import CircularProgress from "@material-ui/core/CircularProgress";
import { errorClass } from '../../../helper';

export const RestorePassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState({email: ''});

  const handleChanges = (event) => {
    setError(null);
    setEmail(event.target.value);
    validateField('email', event.target.value);
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let emailValid = isEmailValid;
    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setIsEmailValid(emailValid);
    if (emailValid) {
      setIsFormValid(true);
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    try {
      setLoading(true);
      const response = await(forgotPassword(email));
      setMessage(response.message);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    };
  };

  return (
      <section className="auth__popup">
      {loading ? (
          <CircularProgress />
        ) : (
          <form
            className={error ? "auth__form--err auth__form" : "auth__form"}
            onSubmit={handleSubmit}
          >
            <Link to="/signin" className="auth__link auth__link--light restore-password__link">
              {`< Back to Sign In`}
            </Link>
            <h1 className="auth__title">
              Restore Password
            </h1>
            <div className="auth__field">
              <label
                htmlFor="email"
                className="auth__label"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                onChange={handleChanges}
                value={email}
                name="email"
                placeholder="Email@example.com"
                title="Email address should be valid and contain '@' symbol"
                className={`auth__input ${errorClass(formErrors.email)}`}
              />
              <p class="error-message">
                {formErrors.email}
              </p>
            </div>
            <button
              type="submit"
              className="auth__button btn"
              disabled={!isFormValid}
              onClick={(event) => handleSubmit(event)}
            >
              Restore Password
            </button>
            <p className="message">
              {message}
            </p>
          </form>
        )}
      </section>
  );
};
