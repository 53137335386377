import React from 'react';

const moment= require('moment');

export const TodoItem = ({ todo }) => {

  return (
    <>
      <td className='sessions__row-item'>{todo.title}</td>
      <td className='sessions__row-item'>{moment(todo.creationDate).format('MM/DD/YYYY')}</td>
      <td className='sessions__row-item'>{moment(todo.deadline).format('MM/DD/YYYY')}</td>
      <td className='sessions__row-item'>
        {todo.priority ? (
            <div className={todo.priority === 'MAJOR'
              ? "modal__colored-tag modal__colored-tag--red"
              : "modal__colored-tag modal__colored-tag--blue"}>
              <span></span>
              {todo.priority}
            </div>
          ) : (
            <span></span>
          )}
      </td>
      <td className='sessions__row-item'>{todo.text.slice(0, 10) + '...'}</td>
    </>
  );
};
