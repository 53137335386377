import { request } from './api';

export const getAllNotes = (proiderId) => {
  return request(`/note/find-by-provider?provider_id=${proiderId}`, {});
};

export const createNote = (note) => {
  return request(`/note`, {
    method: 'POST',
    body: JSON.stringify(note),
  });
};

export const updateNote = (note) => {
  return request(`/note`, {
    method: 'PUT',
    body: JSON.stringify(note),
  });
};

export const getNote = (id) => {
  return request(`/note/${id}`, {});
};

export const removeNote = (id) => {
  return request(`/note/${id}`, {
    method: 'DELETE',
  });
};
