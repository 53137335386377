import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { currentUserInfo } from '../api/auth';
import { actions, selectors } from '../redux/store';
import { clearToken } from "../api/token";

export const LoadInfo = ({children: Component, ...rest}) => {
  const user = useSelector(selectors.getCurrentUser);
  const dispatch = useDispatch();

  const getInfo = async (disp) => {
    try {
      const user = await(currentUserInfo());
      disp(actions.setUser(user));
    } catch (error) {
      clearToken();
    };
  };

  useEffect(() => {
    if(!user){
      getInfo(dispatch);
    }
  }, [dispatch, user]);

  return (
    <>
      {user && Component}
    </>
  );
};
