import React from 'react';
import {
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";
import { createBrowserHistory } from 'history';

import './App.css';
import Autorize from './routes/authorize';
import NonAutorize from './routes/non-authorize';

import { Dashboard } from "./components/Dashboard/Provider";

import { NonAuthorizeContainer } from './containers/NonAuthorizeContainer/NonAuthorizeContainer';
import { AuthorizeContainer } from './containers/AuthorizeContainer/AuthorizeContainer';
import { DashboardContainer } from './containers/DashboardContainer/DashboardContainer';

const history = createBrowserHistory()
function App() {

  return (
      <Switch>
          <DashboardContainer history={history} path='/dashboard' component={Dashboard}/>

          {NonAutorize.map((obj) => {
              return <NonAuthorizeContainer key={obj.path}
                                            history={history}
                                            path={obj.path}
                                            component={obj.component} />
          })}


          {Autorize.map((obj) => {
              return <AuthorizeContainer key={obj.path}
                                         history={history}
                                         path={obj.path}
                                         component={obj.component} />
          })}

        <Redirect from='/' to='/signin' />
      </Switch>
  );
};

export default withRouter(App);
