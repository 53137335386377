import React from 'react';
import { Redirect } from "react-router-dom"

import {
    Route,
} from "react-router-dom";
import { getToken } from "../../api/token";
import { LoadInfo } from "../LoadInfo";

export const DashboardContainer = ({ component: Component, ...rest }) => {
  const token = getToken();

  return (
      <Route {...rest} render={props => (
          token
              ? <LoadInfo><Component {...props} /></LoadInfo>
              : <Redirect to='/' />
      )} />
  );
};
