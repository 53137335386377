import analitycs from './images/analitycs.svg';
import analitycs_blue from './images/analitycs1.svg';
import next from './images/arr_next.svg';
import prev from './images/arr_prev.svg';
import bell from './images/bell.svg';
import bgi from './images/bgi.jpg';
import clients_blue from './images/clients_blue.png';
import clients from './images/clients.png';
import closer from './images/closer.svg';
import dashboard from './images/dashboard.svg';
import trash_bin from './images/delete.svg';
import doctor from './images/doctor.svg';
import edit from './images/edit.svg';
import email from './images/email_1.svg';
import exit from './images/burger.svg';
import todo from './images/listtodo.svg';
import people from './images/Mask.svg';
import menu from './images/menu.svg';
import ok from './images/ok.svg';
import options from './images/options.svg';
import options_grey from './images/options-grey.svg';
import photo from './images/pic.png';
import scheduling_blue from './images/scheduling_blue.png';
import scheduling from './images/scheduling.svg';
import sessions_blue from './images/sessions_blue.png';
import sessions from './images/sessions.png';
import todo_blue from './images/todo_blue.png';
import logo_white from './images/Union-white.svg';
import logo from './images/Union.svg';
import profile from './images/doctor_grey.svg';
import logout from './images/log_out.svg';
import filter from './images/filter.svg';
import add from './images/add.svg';
import notes from './images/notes.svg';
import circle_check from './images/circle_check.svg';
import upload from './images/upload.svg';
import forms from './images/forms.svg';
import forms_blue from './images/forms_blue.svg';
import invoice from './images/invoice.svg';
import money from './images/money-bag.svg';
import home from './images/Union-grey.svg';

const moment= require('moment');

export const images = {
  add,
  analitycs,
  analitycs_blue,
  next,
  prev,
  bell,
  bgi,
  clients_blue,
  clients,
  closer,
  dashboard,
  trash_bin,
  doctor,
  edit,
  email,
  exit,
  todo,
  people,
  menu,
  ok,
  options,
  options_grey,
  photo,
  scheduling_blue,
  scheduling,
  sessions_blue,
  sessions,
  todo_blue,
  logo_white,
  logo,
  profile,
  logout,
  filter,
  notes,
  circle_check,
  upload,
  forms,
  forms_blue,
  invoice,
  money,
  home,
};

export const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const errorClass = (error) => {
  return(error.length === 0 ? '' : 'has-error');
};

export const isLeapYear = (year) => {
    return !((year % 4) || (!(year % 100) && (year % 400)));
  };

export const getDaysInMonth = (date) => {
  return moment(date, "YYYY-MM").daysInMonth()
};

export const getDayOfWeek = (date) => {
  return moment(date, "YYYY-MM").day() - 1;
};

export const getMonthData = (date) => {
  const result = [];
  const daysInMonth = getDaysInMonth(date.format('YYYY-MM'));
  const monthStartsOn = getDayOfWeek(date.format('YYYY-MM'));
  let day = 1;

  for (let i = 0; i < (daysInMonth + monthStartsOn) / 7; i++) {
    result[i] = [];

    for (let j = 0; j < 7; j++) {
      if ((i === 0 && j < monthStartsOn) || day > daysInMonth) {
        result[i][j] = undefined;
      } else {
        result[i][j] = moment(`${date.format('YYYY-MM')}-${day++}`, 'YYYY-MM-DD');
      };
    };
  };

  return result;
};

export const getSlots = (start, end, duration, interval) => {
  const slots = [];
  let currentDate = start;
  const diff = end - start;
  const durationInMS = duration * 6e4;
  const intervalInMS = interval * 6e4;

  for (let i = 0; i < (diff / (durationInMS + intervalInMS)); i++) {
    const begin = new Date(currentDate).toString();
    currentDate += durationInMS;
    const finish = new Date(currentDate).toString();
    currentDate += intervalInMS;
    slots.push({begin, finish})
  };
  console.log(slots);
  return slots;
};

export const stringNormalise = (str) => {
  const newStr = str.split('_').join(' ');
  return newStr.charAt(0).toUpperCase() + newStr.substr(1).toLowerCase();
};
