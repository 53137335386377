import React from 'react';

export const Confirm = ({data, handleClose}) => {

  return (
    <>
      <div className="modal__field">
        <h2 className="modal__secondary-title">
          {`Are you sure you want to ${data.message}?`}
        </h2>
      </div>
      <div className="modal__buttons">
        <button
          type="button"
          onClick={(event) => handleClose(event, data.id)}
          className="modal__button"
        >
          Yes
        </button>
        <button
          type="button"
          onClick={(event) => handleClose(event, null)}
          className="modal__button"
        >
          No
        </button>
      </div>
    </>
  );
};
