import React from 'react';

import { images } from '../../../helper';
const moment = require('moment');

export const ClientsItem = ({ client, handleNotesClick, handleTodosClick }) => {

  const handleNotes = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    handleNotesClick(client);
  };

  const handleTodos = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    handleTodosClick(client);
  };

    return (
    <>
      <td className='clients__row-item'>
        <p className="clients__main-item">
          {client.firstName} {client.lastName}
          {client.todoCount > 0 && (
            <span
              className="clients__row-marker"
              role="presentation"
              onClick={(event) => handleTodos(event)}
              title="You have active todo"
            >
              {client.todoCount}
            </span>
          )}
        </p>
      </td>
      <td className='clients__row-item'>{moment(client.registrationDate).format('MM/DD/YYYY')}</td>
      <td className='clients__row-item'>{client.lastLogin ? moment(client.lastLogin).startOf('hour').fromNow() : '-'}</td>
      <td className='clients__row-item'>
        <p className="clients__row-desc">Forms</p>
        <img
          alt="forms"
          src={images.circle_check}
          className={!client.formsVerified ? 'clients__row-check clients__row-check--disabled' : 'clients__row-check'}
          title={client.formsVerified ? `${client.firstName}'s forms are verified` : `${client.firstName}'s forms are not verified`}
        />
      </td>
      <td className='clients__row-item'>
      <p className="clients__row-desc">Payment</p>
        <img
          alt="payment"
          src={images.circle_check}
          className={(!client.paymentTypeVerified || client.paymentTypeVerified === 'UNSET') ? 'clients__row-check clients__row-check--disabled' : 'clients__row-check'}
          title={(!client.paymentTypeVerified || client.paymentTypeVerified === 'UNSET') ? `${client.firstName}'s payment type is not verified` : `${client.firstName}'s payment type is verified`}
        />
      </td>
      <td className='clients__row-item clients__row-item--notes'>
        <button
          className="clients__row-button"
          onClick={(event) => handleNotes(event)}
          title={`Notes ${client.noteCount}`}
        >
          <img
            src={images.notes}
            alt="notes"
            style={client.noteCount ? {backgroundColor: '#D5DFEC'} : {}}
          />
        </button>
      </td>
    </>
  );
};
