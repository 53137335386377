import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './style.css';

import CircularProgress from "@material-ui/core/CircularProgress";
import { images } from '../../helper';
import { selectors, actions } from '../../redux/store';
import { FormsList } from './List';
import { getAllForms } from '../../api/forms';
import { Modal } from '../Modal';

export const Forms = () => {
    const [objectFormCreate, setObjectFormCreate] = useState(false);
    const [objectFormUpload, setObjectFormUpload] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const forms = useSelector(selectors.getForms);
    const query = useSelector(selectors.getQuery);
    const currentUser = useSelector(selectors.getCurrentUser);
    const dispatch = useDispatch();

    useEffect(() => {
        loadData(dispatch);
    }, [dispatch]);

    const loadData = async (disp) => {
        setIsLoading(true);
        setError(null);
        try {
            const formsData = await(getAllForms());
            disp(actions.setForms(formsData));
        } catch (error) {
            console.log(error);
            setError(error);
        };
        setIsLoading(false);
    };

    const handleChanges = (event) => {
        dispatch(actions.setQuery(event.target.value));
    };

    const handleUpdate = (data) => {
        if (data) {
          loadData(dispatch, currentUser.id);
        };
    
        setObjectFormCreate(null);
        setObjectFormUpload(null);
      };

    return (
        <>
            <section className="header">
                <div className="header__left">
                    <div className="header__pic">
                        <img src={images.forms_blue} alt="clients" />
                    </div>
                    <h1 className="header__title">
                        Forms
                    </h1>

                </div>
                <div className="header__right">
                    <button
                        type="button"
                        className="header__button flex-button"
                        onClick={() => setObjectFormUpload(true)}
                        title="Upload form"
                    >
                        <img
                            src={images.upload}
                            alt="add client"
                            className="flex-button__img"
                        />
                        <p className="flex-button__text">
                            Upload your form
                        </p>
                    </button>
                    <button
                        type="button"
                        className="header__button flex-button"
                        onClick={() => setObjectFormCreate(true)}
                        title="Fill out new form"
                    >
                        <img src={images.add} alt="add client" className="flex-button__img" />
                        <p className="flex-button__text">
                            Fill out new form
                        </p>
                    </button>
                </div>
            </section>

            <section className="clients__nav">
                <input
                    className="header__search"
                    type="text"
                    placeholder="Search"
                    value={query}
                    onChange={handleChanges}
                />
            </section>
            {isLoading ? (
                <CircularProgress className="progress" />
            ) :
                error ? (
                    <p className="clients__message">Not Found</p>
                ) :
                    forms ? (
                        <FormsList loadData={loadData}/>
                    ) : (
                        <p className="clients__message">You don't have forms yet</p>
            )}
            
            {objectFormCreate && <Modal
                isOpen={objectFormCreate ? true : false}
                data={objectFormCreate}
                onClose={(data) => handleUpdate(data)}
                name='form-create'/>}

            {objectFormUpload && <Modal
                isOpen={objectFormUpload ? true : false}
                data={objectFormUpload}
                onClose={(data) => handleUpdate(data)}
                name='form-upload'/>}
        </>
    );
};
