import { request } from './api';

export const getAllServices = () => {
  return request(`/service/find-all`, {});
};

export const getService = (id) => {
  return request(`/service/${id}`, {});
};

