import { Scheduling } from "../components/Scheduling";
import { Todo } from "../components/Todo";
import { Sessions } from "../components/Sessions";
import { Clients } from "../components/Clients";
import { Providers } from "../components/Providers";
import { Forms } from "../components/Forms";
import { Invoice } from "../components/Invoice";

export default [
    {path:'/clients', component: Clients},
    {path:'/providers', component: Providers},
    {path:'/sessions', component: Sessions},
    {path:'/scheduling', component: Scheduling},
    {path:'/todo', component: Todo},
    {path:'/forms', component: Forms},
    {path:'/invoice', component: Invoice},
];
