import React from 'react';

import { useSelector } from 'react-redux';

import { images } from '../../../helper';
import { selectors } from '../../../redux/store';
const moment= require('moment');

export const SessionsItem = ({ session, handleNotesClick, handleTodosClick }) => {
const currentUser= useSelector(selectors.getCurrentUser);
  const handleNotes = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    handleNotesClick(session, null);
  };

  const handleTodos = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    handleTodosClick(session);
  };

  return (
    <>
      {session.client && session.provider ? (
        <td className='sessions__row-item'>
          <p className="clients__main-item">
            {currentUser.type === 'PROVIDER' ? `${session.client.firstName} ${session.client.lastName}` : `${session.provider.firstName} ${session.provider.lastName}`}
            {session.todoCount > 0 && (
              <span
                className="clients__row-marker"
                role="presentation"
                onClick={(event) => handleTodos(event)}
                title="You have active todo"
              >
                {session.todoCount}
              </span>
            )}
          </p>
        </td>
      ) : (
        <td className='sessions__row-item'>{`-`}</td>
      )}
      <td className='sessions__row-item'>{moment(session.sessionDate).format('MM/DD/YYYY')}</td>
      <td className='sessions__row-item'>{moment(session.sessionDate).format('h:mm')}</td>
      <td className='sessions__row-item'>{session.sessionType}</td>
      <td className='sessions__row-item'>
        {session.sessionStatus ? (
            <div className={(session.sessionStatus === 'HIDDEN' || session.sessionStatus === 'MISSED')
              ? "modal__colored-tag modal__colored-tag--red"
              : (session.sessionStatus === 'SCHEDULED'
                ? "modal__colored-tag modal__colored-tag--blue"
                : "modal__colored-tag modal__colored-tag--green"
                )
            }>
              <span></span>
              {session.sessionStatus}
            </div>
          ) : (
            <span></span>
          )}
      </td>
      {currentUser.type === 'PROVIDER' && (
        <td className='sessions__row-item sessions__row-item--notes'>
        <button
          className="clients__row-button"
          onClick={(event) => handleNotes(event)}
          title={`Notes ${session.noteCount}`}
        >
          <img
            src={images.notes}
            alt="notes"
            style={session.noteCount ? {backgroundColor: '#D5DFEC'} : {}}
          />
        </button>
      </td>
      )}
    </>
  );
};
