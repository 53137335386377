import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  overrides: {
    MuiInput: {
      root: {
        color: "#2B2E32",
        border: "1px solid #D5DFEC",
        borderRadius: "8px",
        height: "33px",
        padding: "5px 15px",
        marginBottom: "5px",
        "&::before": {
          display: "none",
        },
      }
    },
  }
});

export default theme;
