import React, { useEffect, useState } from 'react';

import { getFormById } from '../../../../api/forms';
import CircularProgress from "@material-ui/core/CircularProgress";

export const Form = ({ data, handleClose }) => {
  const [selectedForm, setSelectedForm] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  const loadForm = async (id) => {
    setIsLoading(true);
    try {
      const form = await(getFormById(id));
      setSelectedForm(form);
    } catch (error) {
      console.log(error);
    };
    setIsLoading(false);
  };

  useEffect(() => {
    loadForm(selectedForm.id);
  }, [selectedForm.id]);

  return (
    <>
      {isLoading ? (
        <CircularProgress className="progress" />
      ) : (
        <iframe
          src={`https://${selectedForm.pdfUrl}`}
          frameBorder="0"
          className="template__pdf-img"
          title="pdf"
        />
      )}
    </>
  );
};
