import React from 'react';
import { Redirect } from "react-router-dom"

import './Authorised.css';
import Aside from '../../components/Aside';
import {
    Route,
} from "react-router-dom";
import { getToken } from "../../api/token";
import { LoadInfo } from "../LoadInfo";

export const AuthorizeContainer = ({ component: Component, ...rest }) => {
  const token = getToken();

  return (
    <div className="page__container">
      <LoadInfo><Aside /></LoadInfo>
      <main className="content">
        <Route {...rest} render={props => (
            token
                ? <LoadInfo><Component {...props} /></LoadInfo>
                : <Redirect to='/' />
        )} />
      </main>
    </div>
  );
};
