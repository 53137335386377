import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './style.css';

import { selectors, actions } from '../../../redux/store';
import { ClientsItem } from '../Item';
import { Pagination } from '../../Pagination';
import { Modal } from "../../Modal";

export const ClientsList = ({loadData}) => {
  const clients = useSelector(selectors.getClients);
  const query = useSelector(selectors.getQuery);
  const isUserActive = useSelector(selectors.getIsUserActive);
  const [objectNotes, setObjectNotes] = useState(null);
  const [objectTodos, setObjectTodos] = useState(null);
  const [objectClient, setObjectClient] = useState(null);
  const [page, setPage] = useState(1);
  const [pageStart, setPageStart] = useState(0);
  const [pageEnd, setPageEnd] = useState(10);
  const [sort, setSort] = useState('id');
  const dispatch = useDispatch();

  const sortedClients = useMemo(() => {
    return ([...clients]
      .filter(client => ((client.firstName + client.lastName + client.diagnosis).toString().toLowerCase()
              .includes(query.toString().toLowerCase().replace(/ +/g, '')))))
        // eslint-disable-next-line
      .sort((a, b) => {
        if (a[sort]) {
          if (isNaN(a[sort])) {
            return a[sort].localeCompare(b[sort]);
          };

          return a[sort] - b[sort];
        };
      });
  }, [clients, sort, query]);

  const handleNotes = (client) => {
    setObjectNotes({client, session: null});
  };

  const handleTodos = (client) => {
    setObjectTodos({client, session: null});
  };

  const handleUpdate = (data) => {
    if (data) {
      loadData(dispatch);
    };

    setObjectClient(null);
    setObjectNotes(null);
    setObjectTodos(null);
  };

  return (
    <>
      <table className="clients__table">
        <thead>
            <tr className="clients__row-first clients__row">
              <th className='clients__row-item' onClick={() => setSort('firstName')}>Full Name</th>
              <th className='clients__row-item' onClick={() => setSort('registrationDate')}>Enrollment Date</th>
              <th className='clients__row-item' onClick={() => setSort('lastLogin')}>Last Login</th>
              <th className='clients__row-item'>Forms</th>
              <th className='clients__row-item'>Payment</th>
              <th className='clients__row-item'>Notes</th>
            </tr>
        </thead>
        <tbody>
          {sortedClients.slice(pageStart, pageEnd).map(client => (
            <tr
              key={client.id}
              className={client.isRegistred ? "clients__row" : "clients__row clients__row--not-active"}
              onClick={() => setObjectClient(client)}
              title={client.isRegistred ? "Active client" : "Not active client"}
            >
              <ClientsItem handleNotesClick={handleNotes} handleTodosClick={handleTodos} client={client} />
            </tr>
          ))}
        </tbody>
      </table>

      {sortedClients && (
        <Pagination 
          page={page}
          setPage={setPage}
          itemsQuantity={sortedClients.length}
          pageStart={pageStart}
          setPageStart={setPageStart}
          pageEnd={pageEnd}
          setPageEnd={setPageEnd}
        />
      )}

      {objectNotes && <Modal
        isOpen={objectNotes ? true : false}
        data={objectNotes}
        onClose={(data) => handleUpdate(data)}
        name='notes'/>}

      {objectClient && <Modal
        isOpen={objectClient ? true : false}
        data={objectClient}
        onClose={(data) => handleUpdate(data)}
        name='client'/>}

      {objectTodos && <Modal
        isOpen={objectTodos ? true : false}
        data={objectTodos}
        onClose={(data) => handleUpdate(data)}
        name='todos'/>}
        
      {isUserActive && <Modal
        isOpen={isUserActive ? true : false}
        data={isUserActive}
        onClose={() => dispatch(actions.setIsUserActive(null))}
        name='profile'/>}
    </>
  )
}
