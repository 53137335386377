import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectors } from '../../../../redux/store';
import { getAllUsers } from '../../../../api/users';
import { Modal } from '../../../Modal';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from '@material-ui/core/TextField';

export const FormCreate = ({ handleClose }) => {
  const currentUser = useSelector(selectors.getCurrentUser);
  const [form, setForm] = useState({htmlData: {}, providerId: currentUser.id});
  const [objectFormTemplate, setObjectFormTemplate] = useState(null);
  const [clients, setClients] = useState([]);
  const templates = [
    {name: "Insurance information", id: 6},
    {name: "Intake", id: 7},
  ];

  const loadClients = async () => {
    try {
      const clients = await(getAllUsers());
      setClients(clients);
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    if (currentUser.type === 'PROVIDER') {
      loadClients();
    } else {
      setForm({
        ...form,
        clientId: currentUser.id,
      });
    };
  // eslint-disable-next-line
  }, [currentUser.type]);

  const handleClient = (newClient) => {
    if (newClient) {
      setForm({
        ...form,
        clientId: newClient && newClient.id,
      });
    };
  };

  const handleTemplate = (template) => {
    if (template) {
      setForm({
        ...form,
        templateName: template.name,
        templateId: template.id,
      });
    };
  };

  const handleCloseTemplate = () => {
    setObjectFormTemplate(null);
    handleClose(null);
  }

  return (
   <>
      <div className="modal__two-fields">
        {currentUser.type === 'PROVIDER' && (
          <div className="modal__field">
          <Autocomplete
            className="modal__autocomplete"
            id="client-choose"
            getOptionSelected={(option, value) => option.firstName === value.firstName}
            options={clients}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
            style={{ width: '100%' }}
            onChange={(event, newClient) => handleClient(newClient)}
            renderInput={(params) => <TextField {...params} label="Choose client" variant="outlined" />}
          />
        </div>
        )}
        <div className="modal__field">
          <Autocomplete
            className="modal__autocomplete"
            id="template-choose"
            getOptionSelected={(option, value) => option.name === value.name}
            options={templates}
            getOptionLabel={(option) => `${option.id} ${option.name}`}
            style={{ width: '100%' }}
            onChange={(event, newTemplate) => handleTemplate(newTemplate)}
            renderInput={(params) => <TextField {...params} label="Choose form template" variant="outlined" />}
          />
        </div>
      </div>
      <div className="modal__buttons">
        <button
          type="button"
          className="modal__button"
          onClick={() => setObjectFormTemplate(form)}
          disabled={!form.clientId || !form.templateId}
        >
          Get form
        </button>
      </div>

      {objectFormTemplate && <Modal
        isOpen={objectFormTemplate ? true : false}
        data={objectFormTemplate}
        onClose={() => handleCloseTemplate()}
        name='form-template'/>}
    </>
  );
};