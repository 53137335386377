import React, { useState } from 'react';
import { Link } from "react-router-dom"

import { signIn } from '../../../api/auth';
import CircularProgress from "@material-ui/core/CircularProgress";
import { setToken } from "../../../api/token";
import { errorClass } from '../../../helper';

export const SignIn = ({history}) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState({email: ''});

  const handleEmail = (event) => {
    const value = event.target.value;
    setError(null);
    validateField('email', value);
    setEmail(value);
  };

  const handlePassword = (event) => {
    const value = event.target.value;
    setError(null);
    setPassword(value);
  };

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let emailValid = isEmailValid;
    switch(fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setIsEmailValid(emailValid);
    if (emailValid) {
      setIsFormValid(true);
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const token = await(signIn(email, password));
      setToken(token);
      history.push('/dashboard');
    } catch (error) {
      console.log(error);
      setError(error);
    }
    setLoading(false);
  };

  return (
      <section className="auth__popup">
        {loading ? (
            <CircularProgress />
          ) : (
          <form
            className={error ? "auth__form--err auth__form auth__form-signin--err" : "auth__form"}
            onSubmit={(event) => handleSubmit(event)}
          >
            <h1 className="auth__title">Wellcome!</h1>
            <div className="auth__field">
              <label
                htmlFor="email"
                className="auth__label"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                onChange={(event) => handleEmail(event)}
                value={email}
                name="email"
                placeholder="Email@example.com"
                className={`auth__input ${errorClass(formErrors.email)}`}
              />
              <p class="error-message">
                {formErrors.email}
              </p>
            </div>
            <div className="auth__field">
              <label
                htmlFor="password"
                className="auth__label"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                onChange={(event) => handlePassword(event)}
                value={password}
                name="password"
                placeholder="Password"
                className="auth__input"
                // className={`auth__input ${errorClass(formErrors.password)}`}
              />
              {/* <p class="error-message">
                {formErrors.password}
              </p> */}
            </div>
            <button
              type="submit"
              className="auth__button btn"
              disabled={!isFormValid}
            >
              Sign In
            </button>
            <div className="auth__links">
              <Link to="/restore-password" className="auth__link auth__link--light">
                Forgot password?
              </Link>
              <Link to="/registration" className="auth__link auth__link--registration">
                Registration in system
              </Link>
            </div>
          </form>
        )}
      </section>
  );
};
