import React, { useState } from 'react';

import { getTodo, removeTodo } from '../../../../api/todo';
import { images } from '../../../../helper';
import { Modal } from '../../../Modal';
import CircularProgress from "@material-ui/core/CircularProgress";

const moment= require('moment');

export const Todo = ({ data, handleClose }) => {
  const [selectedTodo, setSelectedTodo] = useState(data);
  const [objectTodoEdit, setObjectTodoEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [objectConfirm, setObjectConfirm] = useState(null);

  const loadTodo = async (id) => {
    setIsLoading(true);
    try {
      const todo = await(getTodo(id));
      setSelectedTodo(todo);
    } catch (error) {
      console.log(error);
      handleClose(null);
    };
    setIsLoading(false);
  };

  const remove = async (id) => {
    try {
      setIsLoading(true);
      await(removeTodo(id));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      handleClose(null, id);
    };
  };

   const handleUpdate = (data) => {
    if (data) {
      loadTodo(selectedTodo.id);
    };

    setObjectTodoEdit(null);
  };

  const handleConfirm = (data) => {
    if (data) {
      remove(selectedTodo.id);
    };

    setObjectConfirm(null);
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress className="progress" />
      ) : (
        <>
          <div className="modal__content">
            <div className="modal__content-block">
              <h2 className="modal__secondary-title highlighted">
                {selectedTodo.title}
              </h2>
              <p className="">
                {selectedTodo.text}
              </p>
            </div>
            <div className="modal__two-fields">
              <div className="modal__field">
                <h3 className="modal__secondary-title">
                  Created
                </h3>
                <p className="">
                  {moment(selectedTodo.creationDate).format('MM/DD/YYYY')}
                </p>
              </div>
              <div className="modal__field">
                <h3 className="modal__secondary-title">
                  Deadline
                </h3>
                <p className="">
                  {moment(selectedTodo.deadline).format('MM/DD/YYYY')}
                </p>
              </div> 
            </div>
          </div>
          <div className="modal__buttons">
          {selectedTodo.priority ? (
            <div className={selectedTodo.priority === 'MAJOR'
              ? "modal__colored-tag modal__colored-tag--red"
              : "modal__colored-tag modal__colored-tag--blue"}>
              <span></span>
              {selectedTodo.priority}
            </div>
          ) : (
            <span></span>
          )}
            <div>
              <button
                className="modal__icon-button"
                type="button"
                title="Delete"
                onClick={() => setObjectConfirm({message: 'delete this todo'})}
              >
                <img
                  src={images.trash_bin}
                  alt="delete"
                />
              </button>
              <button
                className="modal__icon-button"
                type="button"
                title="Edit"
                onClick={() => setObjectTodoEdit(selectedTodo)}
              >
                <img
                  src={images.edit}
                  alt="edit"
                />
              </button>
            </div>
          </div>
        </>
      )}

      {objectTodoEdit && <Modal
        isOpen={objectTodoEdit ? true : false}
        data={objectTodoEdit}
        onClose={(data) => handleUpdate(data)}
        name='todo-edit'/>}

      {objectConfirm && <Modal
        isOpen={objectConfirm ? true : false}
        data={objectConfirm}
        onClose={(data) => handleConfirm(data)}
        name='confirm'/>}
    </>
  );
};
